import React from "react";

export default function TeclaT() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M 16.50307,137.63884 C 9.94454,135.57136 4.66567,130.91047 1.68651,124.55681 L 0,120.96001 V 69.120004 17.280001 L 1.70266,13.647908 C 4.08652,8.5627 8.01915,4.54585 13.02809,2.079958 L 17.25309,0 H 71.66654 126.08 l 3.6321,1.702661 c 5.08534,2.383919 9.10206,6.316485 11.56794,11.325586 l 2.07996,4.225146 v 52.173305 52.173312 l -2.02532,3.84 c -2.59199,4.91437 -6.5611,8.74191 -11.41468,11.00752 l -3.84,1.79248 -53.44,0.1245 c -42.92558,0.1 -53.97063,-0.0427 -56.13693,-0.72567 z M 57.28,61.760004 57.6,35.200002 65.28,35.042991 c 10.37959,-0.212205 10.56,-0.26768 10.56,-3.247182 0,-1.330894 -0.3456,-2.765407 -0.768,-3.187807 -0.90321,-0.903209 -41.86726,-1.106517 -43.27352,-0.21477 -0.5438,0.344841 -0.77022,1.530082 -0.64,3.3502 l 0.20152,2.81657 8.61429,0.179514 c 5.90681,0.123091 8.77015,0.424819 9.1102,0.96 0.27275,0.429267 0.50032,12.324487 0.50571,26.433819 0.005,14.109331 0.21473,25.858271 0.46522,26.108751 0.25047,0.25048 1.90647,0.37048 3.68,0.26666 L 56.96,88.320006 Z"
        fill="#999"
      />
    </svg>
  );
}
