import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, errorInfo) {
    // Display fallback UI
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo
    });
    // You can also log the error to an error reporting service
    //logErrorToMyService(error, info);
    console.log(errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div>
          <h2>Algo salio mal</h2>
          Tuvimos un error en la cancion con id = {this.props.songID} en modo de{" "}
          {this.props.visual}
          <br />
          Favor comunicarle el error a Pablo Madrigal al correo pablomadrigalq@gmail.com
          <br />
          <br />
          Para seguir usando el favor volver a cargar la pagina o el app
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
