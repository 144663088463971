import React, {useEffect, useState} from "react";
import { Text, View } from "react-native";

export default function Autores(props) {
//Crea los estados necesarios para saber que imprimir
  const [isMusica, setIsMusica] = useState(null);
  const [isLetra, setIsLetra] = useState(null);
  const [isMusicaYLetra, setIsMusicaYLetra] = useState(null);
  const [isTraductor, setIsTraductor] = useState(null);
  const [tipoImpresion, setTipoImpresion] = useState(1);

  /*Definir las variables que van a tener lo que se imprime, ya sean los autores o null*/
  let imprimirTituloAutores;
  let imprimirMusica;
  let imprimirLetra;
  let imprimirMusicaYLetra;
  let imprimirTraduccion;

  useEffect(() => {
    
    setIsMusica(props.autorMusica);
    setIsLetra(props.autorLetra);
    setIsMusicaYLetra(props.autorMyL);
    setIsTraductor(props.autorTraductor);
    setTipoImpresion(props.tipoImpresion);
  }, [props.autorMusica,props.autorLetra,props.autorMyL,props.autorTraductor,props.tipoImpresion]);

  /*Revisar si hay variable de autor con musica */
  if (isMusica) {
    if (tipoImpresion === 1) {
      imprimirMusica = (
        <i>
          <br />
          M: {props.autorMusica}
        </i>
      );
    } else {
      imprimirMusica = <i>{props.autorMusica}, </i>;
    }
  } else {
    imprimirMusica = null;
  }
  /*Revisar si hay variable de autor con letra */
  if (isLetra) {
    if (tipoImpresion === 1) {
      imprimirLetra = (
        <i>
          <br />
          L: {props.autorLetra}
        </i>
      );
    } else {
      imprimirLetra = <i>{props.autorLetra}, </i>;
    }
  } else {
    imprimirLetra = null;
  }
  /*Revisar si hay variable de autor con musica y letra */
  if (isMusicaYLetra) {
    if (tipoImpresion === 1) {
      imprimirMusicaYLetra = (
        <i>
          <br />
          M, L: {props.autorMyL}
        </i>
      );
    } else {
      imprimirMusicaYLetra = <i>{props.autorMyL}, </i>;
    }
  } else {
    imprimirMusicaYLetra = null;
  }
  /*Revisar si hay variable de autor con traduccion */
  if (isTraductor) {
    if (tipoImpresion === 1) {
      imprimirTraduccion = (
        <i>
          <br />
          Tr: {props.autorTraductor}
        </i>
      );
    } else {
      imprimirTraduccion = <i>{props.autorTraductor}, </i>;
    }
  } else {
    imprimirTraduccion = null;
  }
  /* Revisar si hay que poner "Autores" */
  if (tipoImpresion === 2) {
    imprimirTituloAutores = <i> Autores: </i>;
  } else {
    imprimirTituloAutores = null;
  }


  return (
    <View style={{ flex: 1 }}>
      <Text
        style={{
          fontFamily: "Cochin",
          fontSize: 13,
          textAlign: "right",
          color: "#57585b"
        }}
      >
        {imprimirTituloAutores}
        {imprimirMusica}
        {imprimirLetra}
        {imprimirMusicaYLetra}
        {imprimirTraduccion}
      </Text>
    </View>
  );
}

