import React from "react";

export default function TeclaArriba() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M16.503 137.64c-6.563-2.072-11.838-6.73-14.816-13.083L0 120.96V17.28l1.703-3.632C4.087 8.563 8.019 4.546 13.028 2.08L17.253 0H126.08l3.632 1.703c5.085 2.384 9.102 6.316 11.568 11.325l2.08 4.225V121.6l-1.825 3.52c-2.45 4.725-6.325 8.642-10.975 11.095l-3.84 2.025-53.76.126c-43.25.102-54.287-.04-56.457-.725zm31.154-61.758c.426-.795.663-10.528.663-27.2 0-14.28.24-25.962.533-25.962.293 0 4.922 4.464 10.286 9.92 5.364 5.456 10.114 9.92 10.555 9.92.442 0 1.462-.701 2.267-1.559l1.465-1.559-1.303-1.8c-.717-.991-6.743-7.202-13.392-13.802-8.907-8.842-12.49-12-13.615-12-1.131 0-4.973 3.433-14.841 13.26-7.323 7.293-13.315 13.678-13.315 14.19 0 1.331 1.922 3.27 3.242 3.27.619 0 5.423-4.464 10.676-9.92 5.253-5.456 9.887-9.92 10.297-9.92.555 0 .745 6.747.745 26.432 0 19.705.195 26.627.768 27.2 1.217 1.217 4.219.933 4.97-.47z"
        fill="#999"
      />
    </svg>
  );
}
