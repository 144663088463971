import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";

import VolumeDown from "@material-ui/icons/VolumeDown";
import VolumeUp from "@material-ui/icons/VolumeUp";

import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(1, 1),
        flexGrow: 1,
        width: 50
    },
    slider: {
        margin: "auto",
        height: 100
    }
}));

export default function ControlVolumen(props) {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <Grid
                container
                direction="column"
                alignItems="center"
                justify="space-between"
                spacing={2}
            >
                <Grid item>
                    <IconButton onClick={() => props.volumeChangeButton(100)}>
                        <VolumeUp />
                    </IconButton>
                </Grid>
                <Grid item>
                    <div className={classes.slider}>
                        <Slider
                            orientation="vertical"
                            value={props.volumen}
                            onChange={props.onChange}
                            aria-labelledby="continuous-slider"
                        />
                    </div>
                </Grid>
                <Grid item>
                    <IconButton onClick={() => props.volumeChangeButton(0)}>
                        <VolumeDown />
                    </IconButton>
                </Grid>
            </Grid>
        </Paper>
    );
}

ControlVolumen.propTypes = {
    onPlay: PropTypes.func
};
