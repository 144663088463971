/*global Mousetrap*/
import React from "react";
import PropTypes from "prop-types";
import compose from "recompose/compose";

/* Importamos todo lo de material-ui */
import { withStyles } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";

/* Importamos todo lo de antd */
import { Button, Tooltip } from "antd";

/* Importamos los iconos */
import { 
  ExpandOutlined, 
  CompressOutlined, 
  SwitcherOutlined, 
  FontSizeOutlined,
  MinusCircleOutlined,
  PlusCircleOutlined
} from '@ant-design/icons';

//Importar el modo de FullScreen
import screenfull from "screenfull";

/* Importamos los componentes y datos */
import TextoCompleto from "./Components/TextoCompleto";
import PantallaNegra from "./Components/PantallaNegra";
import { Slider } from "./Components/Slider/Slider"
import { SelectSong } from "./Components/AutocompleteSong/SelectSong"
import ErrorBoundary from "./Components/ErrorBoundary.js";
import MenuOpciones from "./Components/MenuOpciones.js";

//Importamos para detectar si es celular o compu
import { BrowserView } from "react-device-detect";

import Logo from "./assents/Logo";
import ShalomLogo from "./assents/ShalomLogo";

const styles = theme => ({
  root: {
    display: "flex",
    color: 'rgb(37, 75, 112)'
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 20
  },
  hide: {
    display: "none"
  },
  content: {
    flexGrow: 1
  },
  growAppBarText: {
    flexGrow: 1
  },
  growSearchAppBar: {
    flexGrow: 1,
    width: 300
  },
  drawerHeader: {
    ...theme.mixins.toolbar
  }
});

class Cantoral extends React.Component {
  state = {
    viewChangeText: true,
    songId: 1,
    cantoralRia: false,
    visualization: 1,
    anchorEl: null,
    anchorEl2: null,
    tamanoLetraTexto: 0,
    tamanoLetraPresentacion: 0,
    pantallaNegra: false,
    pantallaCompleta: false,
    logoShalom: false
  };

  componentDidMount() {
    Mousetrap.bindGlobal("shift+p", e => {
      this.setState({
        visualization: 2,
        viewChangeText: false,
        pantallaNegra: false
      });
      return false;
    });

    Mousetrap.bindGlobal("shift+t", e => {
      this.setState({
        visualization: 1,
        viewChangeText: true,
        pantallaNegra: false
      });
      return false;
    });

    Mousetrap.bindGlobal("shift+n", e => {
      var temp = !this.state.pantallaNegra;
      this.setState({ pantallaNegra: temp });
      this.props.cambiarPantalla();
      return false;
    });

    Mousetrap.bindGlobal("shift+f", e => {
      this.ponerFullScreen();
      return false;
    });

    Mousetrap.bindGlobal("shift+s", e => {
      this.ponerLogoShalom();
      return false;
    });
  }

  handleDrawerOpen = () => {
    this.setState({ openDrawer: true });
  };

  handleDrawerClose = () => {
    this.setState({ openDrawer: false });
  };

  handleChangeAutoComplete = (cancionId) => {
    this.setState({
      songId: cancionId,
      openDrawer: false
    });
  };

  handleChangeCantoral = cantoralRiaTrue => {
    this.setState({ cantoralRia: cantoralRiaTrue });
  };

  handleVisualClick = event => {
    this.setState({ anchorEl: event.currentTarget});
  };

  handleVisualClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSizeClick = event => {
    this.setState({ anchorEl2: event.currentTarget });
  };

  handleFullScreen = event => {
    this.ponerFullScreen();
  };

  ponerFullScreen = () => {
    screenfull.toggle();
    let newState = !this.state.pantallaCompleta;
    this.setState({ pantallaCompleta: newState });
  };

  ponerLogoShalom = () => {
    let newState = !this.state.logoShalom;
    this.setState({ logoShalom: newState });
  };

  handleSizeClose = () => {
    this.setState({ anchorEl2: null });
  };

  handleVisualTextClose = () => {
    this.setState({ anchorEl: null, visualization: 1, viewChangeText: true });
  };

  handleVisualPresClose = () => {
    this.setState({ anchorEl: null, visualization: 2, viewChangeText: false });
  };

  handleMoreSize = () => {
    let change = 0;
    if (this.state.visualization === 1) {
      change = this.state.tamanoLetraTexto + 1;
      this.setState({ anchorEl: null, tamanoLetraTexto: change });
    } else {
      change = this.state.tamanoLetraPresentacion + 3;
      if (change > 50) change = 50;
      this.setState({ anchorEl: null, tamanoLetraPresentacion: change });
    }
  };

  handleLessSize = () => {
    let change = 0;
    if (this.state.visualization === 1) {
      change = this.state.tamanoLetraTexto - 1;
      this.setState({ anchorEl: null, tamanoLetraTexto: change });
    } else {
      change = this.state.tamanoLetraPresentacion - 3;
      if (change < -30) change = -30;
      this.setState({ anchorEl: null, tamanoLetraPresentacion: change });
    }
  };

  visualizationOption = () => {
    let visualization = null;
    if (this.state.visualization === 1) {
      visualization = (
        <ErrorBoundary songID={this.state.songId} visual="Texto">
          <TextoCompleto
            songID={this.state.songId}
            tipoCantoral={this.state.cantoralRia}
          />
        </ErrorBoundary>
      );
    } else {
      visualization = (
        <ErrorBoundary songID={this.state.songId} visual="Presentación">
          <Slider
            songID={this.state.songId}
            tamanoLetra={this.state.tamanoLetraPresentacion}
            tipoCantoral={this.state.cantoralRia} 
            cycle={true}
          />
        </ErrorBoundary>
      );
    }
    return <div>{visualization}</div>;
  };

  showFullScreenIcon = () => {
    if (this.state.pantallaCompleta) {
      return <CompressOutlined />;
    } else {
      return <ExpandOutlined />;
    }
  };

  render() {
    const { classes } = this.props;
    const {
      anchorEl,
      anchorEl2,
      viewChangeText,
      pantallaNegra,
      logoShalom
    } = this.state;

    let visual = this.visualizationOption();
    let fullScreenIcon = this.showFullScreenIcon();

    if (!pantallaNegra) {
      return (
        <div className={classes.root}>
          <CssBaseline />
          <AppBar position="fixed" style={{backgroundColor: '#395479'}}>
            <Toolbar>
            {logoShalom ? <ShalomLogo/> : <Logo/>}
              <Typography
                className={classes.growAppBarText}
                component="h1"
                variant="h6"
                color="inherit"
                noWrap
              >
                Cantoral App
              </Typography>

              <SelectSong
                selectSongID={this.handleChangeAutoComplete}
                cantoralRIA={this.state.cantoralRia}
              />
              {!viewChangeText && <Tooltip title="Cambiar tamaño de letra">
                <Button
                  shape="circle"
                  type="text"
                  size="large"
                  icon={<FontSizeOutlined />}
                  onClick={this.handleSizeClick}
                  style={{color: '#ffffff' }} 
                />
              </Tooltip>}
              <Menu
                id="simple-menu"
                anchorEl={anchorEl2}
                open={Boolean(anchorEl2)}
                onClose={this.handleSizeClose}
              >
                <MenuItem onClick={this.handleMoreSize}>
                  <PlusCircleOutlined />
                  Aumentar
                </MenuItem>
                <MenuItem onClick={this.handleLessSize}>
                  <MinusCircleOutlined />
                  Disminuir
                </MenuItem>
              </Menu>
              <BrowserView>
                <Tooltip title="Cambiar pantalla">
                  <Button
                    shape="circle"
                    type="text"
                    size="large"
                    icon={<SwitcherOutlined />}
                    onClick={this.handleVisualClick}
                    style={{color: '#ffffff' }} 
                  />
                </Tooltip>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={this.handleVisualClose}
                >
                  <MenuItem onClick={this.handleVisualTextClose}>
                    Texto Completo
                  </MenuItem>
                  <MenuItem onClick={this.handleVisualPresClose}>
                    Presentacion
                  </MenuItem>
                </Menu>
              </BrowserView>
                <Tooltip title="Pantalla completa">
                  <Button
                    shape="circle"
                    type="text"
                    size="large"
                    icon={fullScreenIcon}
                    onClick={this.handleFullScreen}
                    style={{color: '#ffffff' }} 
                  />
                </Tooltip>
              <MenuOpciones onChangeCantoral={this.handleChangeCantoral}/>
            </Toolbar>
          </AppBar>
          <main className={classes.content}>
            <div className={classes.drawerHeader} />
            {visual}
          </main>
        </div>
      );
    } else {
      return <PantallaNegra />;
    }
  }
}

Cantoral.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired
};

export default compose(
  withStyles(styles, { withTheme: true })
)(Cantoral);
