import React, { useState, useRef } from "react";
import { AutoCompleteSong } from "./AutocompleteSong";
import useOutsideClick from "../../Hooks/useClickOutsideListenerRef";
import { Button, Tooltip } from "antd";
import { SearchOutlined } from "@ant-design/icons";

interface selectSongProps {
    selectSongID: (value: any) => void;
    cantoralRIA: boolean;
}

export const SelectSong: React.FC<selectSongProps> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement>(null);
  const { selectSongID, cantoralRIA } = props;

  useOutsideClick(ref, () => {
    if (show) setShow(false);
  });

  const selectSong = (idSong:any) =>{
    setShow(false);
    selectSongID(idSong);
  }

  return (
    <div className="App">
      {!show && (
        <Tooltip title="Buscar canción">
          <Button
            shape="circle"
            type="text"
            size="large"
            icon={<SearchOutlined />}
            onClick={() => setShow(true)}
            style={{color: '#ffffff' }} 
          />
        </Tooltip>
      )}
      {show && (
        <div ref={ref}>
          <AutoCompleteSong
            cantoralRIA = {cantoralRIA}
            selectSongID = {selectSong}
          />
        </div>
      )}
    </div>
  );
}
