import React from "react";

export default function TeclaIzquierda() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="m 19.816465,139.44818 c -4.49748,-0.78231 -8.97523,-3.18558 -12.4969203,-6.70726 -3.81318,-3.81318 -5.76607,-7.69374 -6.66749005,-13.24887 -0.86049,-5.30287 -0.87338,-92.6987 -0.0145,-98.638227 C 2.2595347,9.6364497 10.055375,2.0452827 21.524475,0.51527806 c 3.24391,-0.432745 22.68062,-0.600563 54.93983,-0.474356 l 49.919995,0.1953 4.16,1.97782564 c 5.00366,2.378936 7.7001,4.57055 10.33477,8.3999203 3.7722,5.482704 3.73004,4.811453 3.73004,59.382246 0,53.292666 -0.0255,53.768166 -3.1576,58.835966 -2.14778,3.47519 -7.40988,7.83007 -11.41817,9.44963 l -3.64904,1.4744 -51.839995,0.0971 c -28.512,0.0534 -53.1395,-0.1289 -54.72779,-0.40517 z m 29.31979,-57.739966 c 0.4224,-0.4224 0.768,-1.18705 0.768,-1.69923 0,-0.51218 -4.04364,-4.81165 -8.98588,-9.55437 -4.94223,-4.74272 -8.87838,-8.946069 -8.74701,-9.340764 0.16751,-0.503257 6.81891,-0.813222 22.26588,-1.037632 l 22.02701,-0.32 v -1.919999 -1.92 l -21.86748,-0.32 c -12.02711,-0.176 -22.09325,-0.546144 -22.36921,-0.822547 -0.27596,-0.276404 3.58841,-4.600005 8.58747,-9.608007 4.99908,-5.008003 9.08922,-9.41722 9.08922,-9.79826 0,-0.38104 -0.58595,-1.223075 -1.30211,-1.871188 -1.2859,-1.163731 -1.45306,-1.027418 -13.43999,10.959508 -7.28113,7.281122 -12.1379,12.626855 -12.1379,13.359886 0,1.489254 22.86006,24.660603 24.32931,24.660603 0.55808,0 1.36029,-0.3456 1.78269,-0.768 z"
        fill="#999"
      />
    </svg>
  );
}
