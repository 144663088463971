import React from "react";

export default function TeclaP() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M 16.50307,137.63883 C 9.94455,135.57135 4.66567,130.91046 1.68651,124.5568 L 0,120.96 V 69.120002 17.280001 L 1.70266,13.647908 C 4.08652,8.5627 8.01916,4.54585 13.0281,2.079958 L 17.25309,0 H 71.66654 126.08 l 3.6321,1.702661 c 5.08534,2.383919 9.10206,6.316485 11.56794,11.325586 L 143.36,17.253393 V 69.426692 121.6 l -2.02532,3.84 c -2.59199,4.91437 -6.5611,8.74191 -11.41468,11.00752 l -3.84,1.79248 -53.44,0.1245 c -42.92558,0.1 -53.97062,-0.0427 -56.13693,-0.72567 z M 45.56223,87.840002 c 0.28477,-0.44 0.66177,-5.552 0.83777,-11.36 l 0.32,-10.56 6.4,-0.41248 c 11.30137,-0.72837 16.40834,-3.4496 20.245,-10.787519 1.25473,-2.399776 1.50658,-3.840454 1.51041,-8.64 0.004,-5.157677 -0.18857,-6.108577 -1.84266,-9.093421 -3.67295,-6.627976 -8.44568,-8.581718 -21.98657,-9.000341 -8.31635,-0.257104 -9.99054,-0.146444 -11.36,0.750862 L 38.08,29.78951 v 28.998582 c 0,15.94921 0.192,29.19057 0.42667,29.42524 0.76275,0.76276 6.51735,0.45826 7.05556,-0.37333 z M 47.2,58.817232 c -0.59787,-0.27195 -0.8,-3.341971 -0.8,-12.15056 v -11.78667 h 5.97934 c 4.99799,0 6.43414,0.240506 8.75044,1.465389 7.90163,4.178483 7.2408,17.865262 -1.02943,21.320781 -2.18925,0.91473 -11.57818,1.75247 -12.90035,1.15106 z"
        fill="#999"
      />
    </svg>
  );
}
