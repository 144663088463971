import React from "react";

export default function TeclaShift() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="m 16.324486,138.15254 c -5.2002,-1.5969 -10.3498697,-5.66037 -13.3612697,-10.54302 -3.06940003,-4.97669 -3.08705003,-5.33929 -2.89665003,-59.517173 l 0.17857,-50.81235 1.79168003,-3.84 C 4.3691263,8.4413267 8.7208963,4.068867 13.614486,1.807303 L 17.525136,0 h 54.4 54.399994 l 3.84,1.793448 c 6.38406,2.981629 10.81311,8.0356387 12.74605,14.544529 0.88728,2.9878 1.01395,9.62785 1.01395,53.15078 0,48.750603 -0.0267,49.822843 -1.3468,54.096833 -1.16012,3.75603 -1.94006,4.95368 -5.62699,8.64062 -3.68694,3.68693 -4.88459,4.46687 -8.64062,5.62699 -4.28702,1.32413 -5.23997,1.34605 -56.613004,1.30222 -44.49982,-0.0379 -52.71559,-0.18676 -55.37323,-1.00288 z m 49.54389,-69.032533 c 0.18879,-10.912 0.40479,-19.91201 0.48,-20.00001 0.0752,-0.088 4.00776,-0.16 8.73899,-0.16 4.73122,0 8.74695,-0.23416 8.92383,-0.52036 0.17688,-0.2862 -7.8,-8.63822 -17.72641,-18.56004 l -18.048,-18.03967 -18.10042,18.10878 c -9.95523,9.95983 -17.89735,18.31184 -17.64916,18.56004 0.2482,0.24819 4.26045,0.45125 8.91612,0.45125 7.12845,0 8.52619,0.15983 8.85334,1.01235 0.21366,0.55679 0.38847,9.43679 0.38847,19.73334 0,10.29654 0.1966,18.91759 0.4369,19.15788 0.24029,0.2403 8.08829,0.3603 17.44,0.26667 l 17.0031,-0.17023 z m -27.04,11.68 c -0.0752,-0.088 -0.29121,-9.088 -0.48,-20 l -0.34324,-19.84001 -3.04,-0.19506 c -1.89637,-0.12169 -3.04,-0.49517 -3.04,-0.99279 0,-0.86662 15.45825,-16.41215 16.32,-16.41215 0.86175,0 16.32,15.54553 16.32,16.41215 0,0.49762 -1.14363,0.8711 -3.04,0.99279 l -3.04,0.19506 -0.34325,19.84001 c -0.18878,10.912 -0.40478,19.912 -0.48,20 -0.0752,0.088 -4.31275,0.16 -9.41675,0.16 -5.104,0 -9.34154,-0.072 -9.41676,-0.16 z"
        fill="#999"
      />
    </svg>
  );
}
