import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import {
  LeftOutlined,
  RightOutlined,
  RetweetOutlined
} from "@ant-design/icons";
import { canciones } from "../../Data/datos"
import { CancionV1, Verso } from "../../Data/types";
import "./Slider.css";
import useKeyPress from "../../Hooks/useKeyPress";

interface sliderProps {
  songID: number;
  tipoCantoral: boolean;
  tamanoLetra: number;
  cycle: boolean;
}

export const Slider: React.FC<sliderProps> = (props) => {
  const [song, setSong] = useState<CancionV1>(canciones[0]);
  const [songLabel, setSongLabel] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [maxsize, setMaxsize] = useState(0);
  const [slides, setSlides] = useState<string[][]>([]);

  const songTitleStyle: React.CSSProperties = {
    fontFamily: "Cochin",
    fontSize: 30,
    textAlign: "left",
    color: "#57585b"
  };

  const songAutorsStyle: React.CSSProperties = {
    fontFamily: "Cochin",
    fontSize: 20,
    textAlign: "right",
    color: "#57585b"
  };

  const songTextStyle: React.CSSProperties = {
    fontSize: 40+props.tamanoLetra,
    textAlign: "center",
    margin: 3
  };

  useEffect(() => {
    var songList = canciones.filter((x: CancionV1) => x.id === props.songID);
    var songTemp = songList[0];
    if (songTemp) {
      setSong(songTemp);
    }
    setCurrentIndex(0);
  }, [props.songID]);

  useEffect(() => {
    let versosOrginal: Verso[] = song.versos;
    let ordenVersos: String[] = song.ordenVersos;
    let versosOrdenados: (Verso | undefined)[] = ordenVersos.map((item) => {
      return versosOrginal.find((o) => o.tipoVerso === item);
    });
    let diapositivas: string[][] = [];
    versosOrdenados.forEach(function (element) {
      let temporal: string[] = [];
      if (element && element.lineas.length > 8) {
        let medio: number = Math.floor(element.lineas.length / 2);
        for (var i = 0; i < medio; i++) {
          temporal.push(element.lineas[i].letra);
        }
        diapositivas.push(temporal);
        temporal = [];
        for (var j = medio; j < element.lineas.length; j++) {
          temporal.push(element.lineas[j].letra);
        }
        diapositivas.push(temporal);
      } else {
        if (element) {
          for (var k = 0; k < element.lineas.length; k++) {
            temporal.push(element.lineas[k].letra);
          }
        }
        diapositivas.push(temporal);
      }
    });
    setMaxsize(diapositivas.length - 1);
    setSlides(diapositivas);
  }, [song]);

  useEffect(() => {
    var number = song.Jesed2016;
    if (props.tipoCantoral === false) {
      number = song.CADV2011.toString();
    }
    setSongLabel(number + " " + song.nombre);
  }, [song, props.tipoCantoral]);

  const goToPrevSlide = () => {
    if (currentIndex === 0) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const goToNextSlide = () => {
    if (currentIndex === maxsize) {
      if (props.cycle) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(maxsize);
      }
    } else {
      setCurrentIndex(currentIndex + 1);
    }
  };

  const rightPress: boolean = useKeyPress("ArrowRight");
  const leftPress: boolean = useKeyPress("ArrowLeft");
  const upPress: boolean = useKeyPress("ArrowUp");
  const downPress: boolean = useKeyPress("ArrowDown");
  useEffect(() => {
    if (rightPress || downPress) {
      goToNextSlide();
    }
    // eslint-disable-next-line
  }, [rightPress, downPress]);

  useEffect(() => {
    if (leftPress || upPress) {
      goToPrevSlide();
    }
    // eslint-disable-next-line
  }, [leftPress, upPress]);

  const printButtons = () => {
    let buttons = [];
    for (let i = 0; i < maxsize + 1; i++) {
      var selected = false;
      if (i === currentIndex) {
        selected = true;
      }
      buttons.push(
        <button
          key={i}
          className={`carousel__dot${selected ? " is-selected" : ""}`}
          onClick={() => setCurrentIndex(i)}
        />
      );
    }
    return buttons;
  };

  const printAutors = () => {
    var listaAutores: string[] = [];
    if (song.autorMYL !== null) {
      listaAutores.push(song.autorMYL);
    }
    if (song.autorMusica !== null) {
      listaAutores.push(song.autorMusica);
    }
    if (song.autorLetra !== null) {
      listaAutores.push(song.autorLetra);
    }
    if (song.autorTrad !== null) {
      listaAutores.push(song.autorTrad);
    }
    var autores = "";
    for (let index = 0; index < listaAutores.length; index++) {
      autores += listaAutores[index];
      if (index < listaAutores.length - 1) autores += ", ";
    }
    return <div style={songAutorsStyle}>{autores}</div>;
  };

  return (
    <div>
      <Grid container spacing={0} direction="row" alignItems="stretch">
        <Grid item zeroMinWidth>
          <Button className="arrowButton" onClick={goToPrevSlide} size="large">
            {currentIndex > 0 && <LeftOutlined />}
          </Button>
        </Grid>
        <Grid item xs zeroMinWidth>
          <div className="slide__wrapper">
            <div style={songTextStyle}>
              {slides[currentIndex] &&
                slides[currentIndex].map((item, index) => {
                  return <div key={index}>{item}</div>;
                })}
            </div>
          </div>
          <Grid
            container
            spacing={0}
            direction="row"
            alignItems="stretch"
            justify="center"
          >
            <Grid item xs zeroMinWidth>
              <div style={songTitleStyle}>
                {songLabel}
                <br />
              </div>
            </Grid>
            <Grid item xs={6} zeroMinWidth>
              <div className="carousel__dots">{printButtons()}</div>
            </Grid>
            <Grid item xs zeroMinWidth>
              {printAutors()}
            </Grid>
          </Grid>
        </Grid>
        <Grid item zeroMinWidth>
          <Button className="arrowButton" onClick={goToNextSlide} size="large">
            {currentIndex < maxsize ? <RightOutlined /> : <RetweetOutlined />}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
