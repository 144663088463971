export const linksAudios = [
    {id:1, link1:"https://riamusica.org/1-la-espada-del-espiritu/", link2:"https://riamusica.org/wp-content/uploads/2018/06/1-La-Espada-del-Espíritu.mp3"},
    {id:2, link1:"https://riamusica.org/2-nacimos-en-una-batalla/", link2:"https://riamusica.org/wp-content/uploads/2018/06/2-Nacimos-en-una-batalla.mp3"},
    {id:3, link1:"https://riamusica.org/3-senor-tu-reinas/", link2:"https://riamusica.org/wp-content/uploads/2018/06/3-Señor-Tú-reinas.mp3"},
    {id:4, link1:"https://riamusica.org/4-a-quien-tengo-en-el-cielo-salmo-73/", link2:"https://riamusica.org/wp-content/uploads/2018/06/4-¿A-quién-tengo-en-el-cielo-Salmo-73.mp3"},
    {id:5, link1:"https://riamusica.org/5-la-voz-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/5-La-voz-del-Señor.mp3"},
    {id:6, link1:"https://riamusica.org/6-grande-es-nuestro-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/6-Grande-es-nuestro-Dios.mp3"},
    {id:7, link1:"https://riamusica.org/cantoral-central/audios/7-al-senor-invocare-2/", link2:"https://riamusica.org/wp-content/uploads/2018/09/7-Al-Señor-Invocare.mp3"},
    {id:8, link1:"https://riamusica.org/8-shema-israel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/8-Shema-Israel.mp3"},
    {id:9, link1:"https://riamusica.org/9-tu-eres-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/9-Tu-eres-mi-Dios.mp3"},
    {id:10, link1:"https://riamusica.org/10-cantad-alegres-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/10-Cantad-alegres-a-Dios.mp3"},
    {id:11, link1:"https://riamusica.org/11-ciudad-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/11-Ciudad-de-Dios.mp3"},
    {id:12, link1:"https://riamusica.org/12-gustad-y-ved/", link2:"https://riamusica.org/wp-content/uploads/2018/06/12-Gustad-y-ved.mp3"},
    {id:13, link1:"https://riamusica.org/13-den-al-senor-sus-alabanzas/", link2:"https://riamusica.org/wp-content/uploads/2018/06/13-Den-al-Senor-sus-Alabanzas.mp3"},
    {id:14, link1:"https://riamusica.org/cantoral-central/audios/14-honra-y-gloria-2/", link2:"https://riamusica.org/wp-content/uploads/2018/09/14-Honra-y-Gloria.mp3"},
    {id:15, link1:"https://riamusica.org/15-gloria-por-siempre/", link2:"https://riamusica.org/wp-content/uploads/2018/06/15-Gloria-por-siempre.mp3"},
    {id:16, link1:"https://riamusica.org/16-alzad-estandartes-de-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/16-Alzad-estandartes-de-amor.mp3"},
    {id:17, link1:"https://riamusica.org/17-nombre-sobre-todo-nombre/", link2:"https://riamusica.org/wp-content/uploads/2018/06/17-Nombre-sobre-todo-nombre.mp3"},
    {id:18, link1:"https://riamusica.org/18-este-es-mi-deseo-honrarte-oh-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/18-Este-es-mi-deseo-honrarte-oh-Dios.mp3"},
    {id:19, link1:"https://riamusica.org/19-yo-celebrare-tu-amor-eternamente/", link2:"https://riamusica.org/wp-content/uploads/2018/06/19-Yo-celebraré-tu-amor-eternamente.mp3"},
    {id:20, link1:"https://riamusica.org/20-exaltado-seas-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/20-Exaltado-seas-Señor.mp3"},
    {id:21, link1:"https://riamusica.org/21-nuestro-dios-reina-ya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/21-Nuestro-Dios-reina-ya.mp3"},
    {id:22, link1:"https://riamusica.org/cantoral-central/audios/22-vengan-cantemos-de-gozo-al-senor-2/", link2:"https://riamusica.org/wp-content/uploads/2018/09/22-Vengan-cantemos-de-gozo-al-Señor.mp3"},
    {id:23, link1:"https://riamusica.org/23-somos-pueblo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/23-Somos-pueblo.mp3"},
    {id:24, link1:"https://riamusica.org/24-el-senor-es-mi-pastor-salmo-23/", link2:"https://riamusica.org/wp-content/uploads/2018/06/24-El-Señor-es-mi-pastor-Salmo-23.mp3"},
    {id:25, link1:"https://riamusica.org/422-ensename-a-buscarte/", link2:"https://riamusica.org/wp-content/uploads/2018/06/422-Enseñame-a-buscarte.mp3"},
    {id:26, link1:"https://riamusica.org/26-digno-es-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/26-Digno-es-el-Señor.mp3"},
    {id:27, link1:"https://riamusica.org/27-oi-por-fin-una-gran-voz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/27-Oí-por-fin-una-gran-voz.mp3"},
    {id:28, link1:"https://riamusica.org/28-resucito-resucito/", link2:"https://riamusica.org/wp-content/uploads/2018/06/28-Resucitó-resucitó.mp3"},
    {id:29, link1:"https://riamusica.org/29-honor-al-nombre-de-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/29-Honor-al-nombre-de-Jesús.mp3"},
    {id:30, link1:"https://riamusica.org/30-himno-de-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/30-Himno-de-gloria.mp3"},
    {id:31, link1:"https://riamusica.org/31-al-que-nos-amo-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/31-Al-que-nos-amo-1.mp3"},
    {id:32, link1:"https://riamusica.org/32-en-alto-a-cristo-quiero-ver/", link2:"https://riamusica.org/wp-content/uploads/2018/06/32-En-alto-a-Cristo-quiero-ver.mp3"},
    {id:33, link1:"https://riamusica.org/33-los-arboles-del-campo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/33-Los-arboles-del-campo.mp3"},
    {id:34, link1:"https://riamusica.org/34-salmo-150/", link2:"https://riamusica.org/wp-content/uploads/2018/06/34-Salmo-150.mp3"},
    {id:35, link1:"https://riamusica.org/35-arriba-hombres-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/35-Arriba-hombres-de-Dios.mp3"},
    {id:36, link1:"https://riamusica.org/36-oh-tu-fidelidad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/36-¡Oh-tu-fidelidad.mp3"},
    {id:37, link1:"https://riamusica.org/37-muestrame-senor-tus-caminos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/37-Muéstrame-Señor-tus-caminos.mp3"},
    {id:38, link1:"https://riamusica.org/38-oh-senor-quiero-alabarte/", link2:"https://riamusica.org/wp-content/uploads/2018/06/38-Oh-Señor-quiero-alabarte.mp3"},
    {id:39, link1:"https://riamusica.org/39-grandes-maravillas/", link2:"https://riamusica.org/wp-content/uploads/2018/06/39-Grandes-Maravillas.mp3"},
    {id:40, link1:"https://riamusica.org/40-viva-el-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/40-¡Viva-el-Rey.mp3"},
    {id:40, link1:"https://riamusica.org/viva-el-rey-mix-full/", link2:"https://riamusica.org/wp-content/uploads/2018/06/viva-el-rey.mp3"},
    {id:41, link1:"https://riamusica.org/41-cantad-aleluya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/41-Cantad-Aleluya.mp3"},
    {id:42, link1:"https://riamusica.org/42-isaias-42-alabenlo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/42-Isaías-42-¡Alábenlo.mp3"},
    {id:43, link1:"https://riamusica.org/43-acerquemonos-al-trono-de-la-gracia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/43-Acerquémonos-al-trono-de-la-gracia.mp3"},
    {id:44, link1:"https://riamusica.org/44-lo-que-respira-salmo-150/", link2:"https://riamusica.org/wp-content/uploads/2018/06/44-Lo-que-respira-Salmo-150.mp3"},
    {id:45, link1:"https://riamusica.org/45-salve-oh-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/45-Salve-oh-Cristo.mp3"},
    {id:46, link1:"https://riamusica.org/46-canto-de-alegria-al-dios-salvador/", link2:"https://riamusica.org/wp-content/uploads/2018/06/46-Canto-de-alegria-al-Dios-Salvador.mp3"},
    {id:47, link1:"https://riamusica.org/47-yo-te-exalto/", link2:"https://riamusica.org/wp-content/uploads/2018/06/47-Yo-te-exalto.mp3"},
    {id:48, link1:"https://riamusica.org/48-mil-voces-para-celebrar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/48-Mil-voces-para-celebrar.mp3"},
    {id:49, link1:"https://riamusica.org/49-victorioso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/49-Victorioso.mp3"},
    {id:50, link1:"https://riamusica.org/50-la-luz-de-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/50-La-luz-de-Jesús.mp3"},
    {id:51, link1:"https://riamusica.org/51-majestad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/51-Majestad.mp3"},
    {id:52, link1:"https://riamusica.org/52-tu-senor-eres-mi-fuerza-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/52-Tú-Señor-eres-mi-fuerza-2.mp3"},
    {id:53, link1:"https://riamusica.org/53-nuestro-dios-con-su-poder-romanos-8/", link2:"https://riamusica.org/wp-content/uploads/2018/06/53-Nuestro-Dios-con-su-Poder-Romanos-8.mp3"},
    {id:54, link1:"https://riamusica.org/54-abre-mis-ojos-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/54-Abre-mis-Ojos-Señor.mp3"},
    {id:55, link1:"https://riamusica.org/55-el-senor-reina-gocese-la-tierra/", link2:"https://riamusica.org/wp-content/uploads/2018/06/55-El-Señor-reina-gócese-la-Tierra.mp3"},
    {id:56, link1:"https://riamusica.org/56-solo-quiero-conocer-a-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/56-Solo-quiero-conocer-a-Jesus.mp3"},
    {id:57, link1:"https://riamusica.org/57-al-rey-de-los-siglos-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/57-Al-rey-de-los-siglos-1.mp3"},
    {id:58, link1:"https://riamusica.org/58-este-es-el-dia-en-que-actuo-el-senor-salmo-118/", link2:"https://riamusica.org/wp-content/uploads/2018/06/58-Este-es-el-día-en-que-actuó-el-Senor-Salmo-118.mp3"},
    {id:59, link1:"https://riamusica.org/59-magnifiquemos-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/59-Magnifiquemos-al-Señor.mp3"},
    {id:59, link1:"https://riamusica.org/472-magnifiquemos-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/472-Magnifiquemos-al-Señor.mp3"},
    {id:60, link1:"https://riamusica.org/60-mi-fuerza-eres-tu-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/60-Mi-fuerza-eres-tu-Señor.mp3"},
    {id:61, link1:"https://riamusica.org/61-mi-alma-alaba-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/61-Mi-alma-alaba-al-Señor.mp3"},
    {id:62, link1:"https://riamusica.org/62-rendid-honor-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/62-Rendid-honor-al-Señor.mp3"},
    {id:63, link1:"https://riamusica.org/63-dios-es-nuestro-amparo-salmo-46/", link2:"https://riamusica.org/wp-content/uploads/2018/06/63-Dios-es-nuestro-amparo-Salmo-46.mp3"},
    {id:64, link1:"https://riamusica.org/64-libre/", link2:"https://riamusica.org/wp-content/uploads/2018/06/64-Libre.mp3"},
    {id:65, link1:"https://riamusica.org/65-santo-santo-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/65-Santo-Santo-Santo.mp3"},
    {id:66, link1:"https://riamusica.org/66-mirad-dios-es-mi-salvacion/", link2:"https://riamusica.org/wp-content/uploads/2018/06/66-Mirad-Dios-es-mi-Salvación.mp3"},
    {id:67, link1:"https://riamusica.org/67-alaben-al-senor-todos-los-pueblos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/67-Alaben-al-Señor-todos-los-pueblos.mp3"},
    {id:68, link1:"https://riamusica.org/68-luchemos-siempre-en-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/68-Luchemos-Siempre-en-el-Señor.mp3"},
    {id:69, link1:"https://riamusica.org/69-el-es-eterno/", link2:"https://riamusica.org/wp-content/uploads/2018/06/69-El-es-eterno.mp3"},
    {id:70, link1:"https://riamusica.org/70-bueno-es-alabarte/", link2:"https://riamusica.org/wp-content/uploads/2018/06/70-Bueno-es-alabarte.mp3"},
    {id:71, link1:"https://riamusica.org/71-te-doy-gracias-senor-salmo-138/", link2:"https://riamusica.org/wp-content/uploads/2018/06/71-Te-doy-gracias-Senor-Salmo-138.mp3"},
    {id:72, link1:"https://riamusica.org/72-al-rey-de-los-siglos-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/72-Al-Rey-de-los-siglos-2.mp3"},
    {id:72, link1:"https://riamusica.org/425-al-rey-de-los-siglos-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/425-Al-Rey-de-los-siglos-2.mp3"},
    {id:73, link1:"https://riamusica.org/73-maranatha/", link2:"https://riamusica.org/wp-content/uploads/2018/06/73-Maranatha.mp3"},
    {id:74, link1:"https://riamusica.org/74-te-exhaltare-mi-dios-mi-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/74-Te-exhaltaré-mi-Dios-mi-Rey.mp3"},
    {id:76, link1:"https://riamusica.org/76-abrid-paso-al-salvador/", link2:"https://riamusica.org/wp-content/uploads/2018/06/76-Abrid-paso-al-Salvador.mp3"},
    {id:77, link1:"https://riamusica.org/77-grandes-y-maravillosas-son-tus-obras-apocalipsis-15/", link2:"https://riamusica.org/wp-content/uploads/2018/06/77-Grandes-y-maravillosas-son-tus-obras-Apocalipsis-15.mp3"},
    {id:78, link1:"https://riamusica.org/78-que-los-pueblos-te-celebren/", link2:"https://riamusica.org/wp-content/uploads/2018/06/78-Que-los-pueblos-te-celebren.mp3"},
    {id:79, link1:"https://riamusica.org/79-santo-tu-eres-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/79-Santo-tu-eres-Santo.mp3"},
    {id:80, link1:"https://riamusica.org/80-gloria-a-jesucristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/80-Gloria-a-Jesucristo.mp3"},
    {id:81, link1:"https://riamusica.org/81-su-gran-poder-proclamad-salmo-96/", link2:"https://riamusica.org/wp-content/uploads/2018/06/81-Su-gran-poder-proclamad-Salmo-96.mp3"},
    {id:82, link1:"https://riamusica.org/82-hacia-la-casa-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/82-Hacia-la-casa-de-Dios.mp3"},
    {id:83, link1:"https://riamusica.org/83-ven-y-adora/", link2:"https://riamusica.org/wp-content/uploads/2018/06/83-Ven-y-adora.mp3"},
    {id:83, link1:"https://riamusica.org/cantoral-central/audios/83-ven-y-adora-2/", link2:"https://riamusica.org/wp-content/uploads/2018/09/83-Ven-y-Adora.mp3"},
    {id:84, link1:"https://riamusica.org/84-ven-jesus-nuestra-esperanza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/84-Ven-Jesús-nuestra-esperanza.mp3"},
    {id:85, link1:"https://riamusica.org/85-por-los-gloriosos-santos-del-ayer/", link2:"https://riamusica.org/wp-content/uploads/2018/06/85-Por-los-gloriosos-santos-del-ayer.mp3"},
    {id:86, link1:"https://riamusica.org/86-quiero-ser-fiel-a-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/86-Quiero-ser-fiel-a-Cristo.mp3"},
    {id:87, link1:"https://riamusica.org/87-oh-cristo-te-aclamamos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/87-Oh-Cristo-te-aclamamos.mp3"},
    {id:88, link1:"https://riamusica.org/435-por-tu-eterno-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/435-Por-tu-eterno-amor.mp3"},
    {id:90, link1:"https://riamusica.org/90-alaba-alma-mia-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/90-Alaba-alma-mía-al-Señor.mp3"},
    {id:91, link1:"https://riamusica.org/91-hiney-mah-tov/", link2:"https://riamusica.org/wp-content/uploads/2018/06/91-Hiney-Mah-Tov.mp3"},
    {id:92, link1:"https://riamusica.org/92-como-es-maravilloso-salmo-133/", link2:"https://riamusica.org/wp-content/uploads/2018/06/92-Como-es-maravilloso-Salmo-133.mp3"},
    {id:93, link1:"https://riamusica.org/93-hazme-arder-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/93-Hazme-arder-Señor.mp3"},
    {id:93, link1:"https://riamusica.org/494-hazme-arder-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/494-Hazme-arder-Señor.mp3"},
    {id:94, link1:"https://riamusica.org/94-soberano-de-los-cielos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/94-Soberano-de-los-cielos.mp3"},
    {id:95, link1:"https://riamusica.org/95-espiritu-santo-ven-ven/", link2:"https://riamusica.org/wp-content/uploads/2018/06/95-Espíritu-Santo-ven-ven.mp3"},
    {id:96, link1:"https://riamusica.org/96-solo-una-cosa/", link2:"https://riamusica.org/wp-content/uploads/2018/06/96-Sólo-una-cosa.mp3"},
    {id:97, link1:"https://riamusica.org/97-oh-yahve-senor-nuestro-salmo-8/", link2:"https://riamusica.org/wp-content/uploads/2018/06/97-¡Oh-Yahvé-Señor-nuestro-Salmo-8.mp3"},
    {id:98, link1:"https://riamusica.org/98-bueno-es-dar-gracias-a-yahveh/", link2:"https://riamusica.org/wp-content/uploads/2018/06/98-Bueno-es-dar-gracias-a-Yahveh.mp3"},
    {id:99, link1:"https://riamusica.org/99-bendecire-a-yahve-en-todo-tiempo-salmo-34/", link2:"https://riamusica.org/wp-content/uploads/2018/06/99-Bendeciré-a-Yahvé-en-todo-tiempo-Salmo-34.mp3"},
    {id:100, link1:"https://riamusica.org/100-cantemos-al-senor-exodo-15/", link2:"https://riamusica.org/wp-content/uploads/2018/06/100-Cantemos-al-Señor-Exodo-15.mp3"},
    {id:101, link1:"https://riamusica.org/101-aleluya-batid-palmas/", link2:"https://riamusica.org/wp-content/uploads/2018/06/101-Aleluya-batid-palmas.mp3"},
    {id:102, link1:"https://riamusica.org/102-solo-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/102-Solo-Dios.mp3"},
    {id:103, link1:"https://riamusica.org/103-en-los-valles-se-oira-un-canto-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/103-En-los-valles-se-oirá-un-canto-a-Dios.mp3"},
    {id:104, link1:"https://riamusica.org/104-la-voz-del-que-clama-en-el-desierto/", link2:"https://riamusica.org/wp-content/uploads/2018/06/104-La-voz-del-que-clama-en-el-desierto.mp3"},
    {id:105, link1:"https://riamusica.org/105-salmo-145/", link2:"https://riamusica.org/wp-content/uploads/2018/06/105-Salmo-145.mp3"},
    {id:106, link1:"https://riamusica.org/106-cristo-jesus-nuestra-oblacion/", link2:"https://riamusica.org/wp-content/uploads/2018/06/106-Cristo-Jesús-nuestra-oblación.mp3"},
    {id:107, link1:"https://riamusica.org/107-el-magnificat/", link2:"https://riamusica.org/wp-content/uploads/2018/06/107-El-magníficat.mp3"},
    {id:108, link1:"https://riamusica.org/108-salmo-18/", link2:"https://riamusica.org/wp-content/uploads/2018/06/108-Salmo-18.mp3"},
    {id:109, link1:"https://riamusica.org/109-estalla-en-una-cancion-isaias-54/", link2:"https://riamusica.org/wp-content/uploads/2018/06/109-Estalla-en-una-canción-Isaías-54.mp3"},
    {id:110, link1:"https://riamusica.org/110-castillo-fuerte-es-nuestro-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/110-Castillo-fuerte-es-nuestro-Dios.mp3"},
    {id:111, link1:"https://riamusica.org/111-senor-tu-me-has-creado-isaias-43/", link2:"https://riamusica.org/wp-content/uploads/2018/06/111-Señor-Tu-me-has-creado-Isaías-43.mp3"},
    {id:112, link1:"https://riamusica.org/112-levantate-y-brilla-isaias-60/", link2:"https://riamusica.org/wp-content/uploads/2018/06/112-Levántate-y-brilla-Isaías-60.mp3"},
    {id:113, link1:"https://riamusica.org/113-despierta-israel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/113-Despierta-Israel.mp3"},
    {id:114, link1:"https://riamusica.org/114-del-cielo-mira-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/114-Del-Cielo-mira-el-Señor.mp3"},
    {id:115, link1:"https://riamusica.org/115-al-salvador-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/115-Al-Salvador-Jesús.mp3"},
    {id:116, link1:"https://riamusica.org/116-oh-senor-tesoro-nuestro/", link2:"https://riamusica.org/wp-content/uploads/2018/06/116-Oh-Señor-tesoro-nuestro.mp3"},
    {id:117, link1:"https://riamusica.org/117-canten-hijos-de-la-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/117-Canten-hijos-de-la-gloria.mp3"},
    {id:118, link1:"https://riamusica.org/118-nos-hemos-reunido-en-el-nombre-de-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/118-Nos-hemos-reunido-en-el-nombre-de-Cristo.mp3"},
    {id:119, link1:"https://riamusica.org/119-la-coraza-de-san-patricio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/119-La-coraza-de-San-Patricio.mp3"},
    {id:120, link1:"https://riamusica.org/120-senor-quien-entrara-en-tu-santuario/", link2:"https://riamusica.org/wp-content/uploads/2018/06/120-Señor-quién-entrará-en-tu-Santuario.mp3"},
    {id:121, link1:"https://riamusica.org/121-cuan-grandiosas-son-todas-tus-obras/", link2:"https://riamusica.org/wp-content/uploads/2018/06/121-Cuan-grandiosas-son-todas-tus-obras.mp3"},
    {id:121, link1:"https://riamusica.org/445-cuan-grandiosas-son-todas-tus-obras/", link2:"https://riamusica.org/wp-content/uploads/2018/06/445-Cuan-grandiosas-son-todas-tus-obras.mp3"},
    {id:122, link1:"https://riamusica.org/122-amen-amen/", link2:"https://riamusica.org/wp-content/uploads/2018/06/122-Amén-amén.mp3"},
    {id:123, link1:"https://riamusica.org/123-cantico-de-maria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/123-Cántico-de-María.mp3"},
    {id:124, link1:"https://riamusica.org/124-hoy-quiero-cantarle/", link2:"https://riamusica.org/wp-content/uploads/2018/06/124-Hoy-quiero-cantarle.mp3"},
    {id:125, link1:"https://riamusica.org/125-cantico-de-moises/", link2:"https://riamusica.org/wp-content/uploads/2018/06/125-Cántico-de-Moisés.mp3"},
    {id:126, link1:"https://riamusica.org/126-cancion-de-gracias/", link2:"https://riamusica.org/wp-content/uploads/2018/06/126-Canción-de-gracias.mp3"},
    {id:127, link1:"https://riamusica.org/127-porque-el-vive/", link2:"https://riamusica.org/wp-content/uploads/2018/06/127-Porque-El-vive.mp3"},
    {id:128, link1:"https://riamusica.org/128-oh-venid-y-alabad-salmo-95/", link2:"https://riamusica.org/wp-content/uploads/2018/06/128-¡Oh-venid-y-alabad-Salmo-95.mp3"},
    {id:129, link1:"https://riamusica.org/129-vengan-y-subamos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/129-Vengan-y-subamos.mp3"},
    {id:130, link1:"https://riamusica.org/130-el-incienso-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/130-El-incienso-de-Dios.mp3"},
    {id:131, link1:"https://riamusica.org/131-tuyos-senor-la-grandeza-y-el-poder/", link2:"https://riamusica.org/wp-content/uploads/2018/06/131-Tuyos-Señor-la-grandeza-y-el-poder.mp3"},
    {id:132, link1:"https://riamusica.org/132-el-retorno-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/132-El-retorno-del-Señor.mp3"},
    {id:133, link1:"https://riamusica.org/133-en-tu-luz-veo-la-luz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/133-En-tu-luz-veo-la-luz.mp3"},
    {id:134, link1:"https://riamusica.org/134-dios-es-mi-refugio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/134-Dios-es-mi-refugio.mp3"},
    {id:135, link1:"https://riamusica.org/135-daniel-6/", link2:"https://riamusica.org/wp-content/uploads/2018/06/135-Daniel-6.mp3"},
    {id:136, link1:"https://riamusica.org/136-gracias-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/136-Gracias-a-Dios.mp3"},
    {id:137, link1:"https://riamusica.org/137-eres-santo-y-glorioso-salmo-45/", link2:"https://riamusica.org/wp-content/uploads/2018/06/137-Eres-santo-y-glorioso-Salmo-45.mp3"},
    {id:138, link1:"https://riamusica.org/138-oh-dios-se-mi-vision/", link2:"https://riamusica.org/wp-content/uploads/2018/06/138-Oh-Dios-se-mi-visión.mp3"},
    {id:139, link1:"https://riamusica.org/139-salmo-67/", link2:"https://riamusica.org/wp-content/uploads/2018/06/139-Salmo-67.mp3"},
    {id:140, link1:"https://riamusica.org/140-el-espiritu-y-la-novia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/140-El-Espíritu-y-la-novia.mp3"},
    {id:141, link1:"https://riamusica.org/141-haz-brillar-tu-verdad-y-tu-luz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/141-Haz-brillar-tu-verdad-y-tu-luz.mp3"},
    {id:142, link1:"https://riamusica.org/142-nada-te-turbe/", link2:"https://riamusica.org/wp-content/uploads/2018/06/142-Nada-te-turbe.mp3"},
    {id:143, link1:"https://riamusica.org/143-dios-dichoso-y-soberano/", link2:"https://riamusica.org/wp-content/uploads/2018/06/143-Dios-dichoso-y-soberano.mp3"},
    {id:144, link1:"https://riamusica.org/144-digno-es-el-cordero/", link2:"https://riamusica.org/wp-content/uploads/2018/06/144-Digno-es-el-cordero.mp3"},
    {id:145, link1:"https://riamusica.org/145-gloria-a-ti-senor-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/145-Gloria-a-Ti-Senor-Jesus.mp3"},
    {id:146, link1:"https://riamusica.org/146-que-gloriosa-tu-morada/", link2:"https://riamusica.org/wp-content/uploads/2018/06/146-Qué-gloriosa-tu-morada.mp3"},
    {id:147, link1:"https://riamusica.org/147-oh-criaturas-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/147-Oh-criaturas-del-Senor.mp3"},
    {id:148, link1:"https://riamusica.org/148-toda-gloria-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/148-Toda-gloria-a-Ti.mp3"},
    {id:149, link1:"https://riamusica.org/149-santo-eterno-y-poderoso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/149-Santo-eterno-y-poderoso.mp3"},
    {id:150, link1:"https://riamusica.org/150-oh-israel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/150-Oh-Israel.mp3"},
    {id:151, link1:"https://riamusica.org/151-va-a-entrar-el-senor-salmo-24/", link2:"https://riamusica.org/wp-content/uploads/2018/06/151-Va-a-entrar-el-Señor-Salmo-24.mp3"},
    {id:152, link1:"https://riamusica.org/152-tu-reino-ha-comenzado-ya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/152-Tu-reino-ha-comenzado-ya.mp3"},
    {id:153, link1:"https://riamusica.org/153-misericordia-senor-que-he-pecado-salmo-51/", link2:"https://riamusica.org/wp-content/uploads/2018/06/153-Misericordia-Señor-que-he-pecado-Salmo-51.mp3"},
    {id:154, link1:"https://riamusica.org/154-solo-dios-con-su-poder/", link2:"https://riamusica.org/wp-content/uploads/2018/06/154-Solo-Dios-con-su-poder.mp3"},
    {id:155, link1:"https://riamusica.org/155-alzo-mis-ojos-a-los-montes-salmo-121/", link2:"https://riamusica.org/wp-content/uploads/2018/06/155-Alzo-mis-ojos-a-los-montes-Salmo-121.mp3"},
    {id:156, link1:"https://riamusica.org/156-isaias-9/", link2:"https://riamusica.org/wp-content/uploads/2018/06/156-Isaías-9.mp3"},
    {id:157, link1:"https://riamusica.org/157-solo-en-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/157-Solo-en-Dios.mp3"},
    {id:158, link1:"https://riamusica.org/158-la-victoria-es-de-nuestro-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/158-La-victoria-es-de-nuestro-Dios.mp3"},
    {id:159, link1:"https://riamusica.org/159-cancion-de-alabanza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/159-Canción-de-Alabanza.mp3"},
    {id:160, link1:"https://riamusica.org/160-te-deum/", link2:"https://riamusica.org/wp-content/uploads/2018/06/160-Te-Deum.mp3"},
    {id:161, link1:"https://riamusica.org/161-la-primacia-de-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/161-La-primacía-de-Cristo.mp3"},
    {id:162, link1:"https://riamusica.org/162-mi-todo-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/162-Mi-Todo-Mi-Dios.mp3"},
    {id:163, link1:"https://riamusica.org/163-me-entrego-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/163-Me-entrego-a-Ti.mp3"},
    {id:164, link1:"https://riamusica.org/164-gloria-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/164-Gloria-a-Ti.mp3"},
    {id:165, link1:"https://riamusica.org/165-fuente-de-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/165-Fuente-de-vida.mp3"},
    {id:166, link1:"https://riamusica.org/166-dios-mio-tu-eres-mi-dios-salmo-63/", link2:"https://riamusica.org/wp-content/uploads/2018/06/166-Dios-mío-tu-eres-mi-Dios-Salmo-63.mp3"},
    {id:167, link1:"https://riamusica.org/167-amarte-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/167-Amarte-Señor.mp3"},
    {id:168, link1:"https://riamusica.org/168-santo-oh-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/168-Santo-Oh-Santo.mp3"},
    {id:169, link1:"https://riamusica.org/169-somos-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/169-Somos-del-Señor.mp3"},
    {id:170, link1:"https://riamusica.org/170-honor-y-gloria-a-nuestro-redentor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/170-Honor-y-gloria-a-nuestro-redentor.mp3"},
    {id:171, link1:"https://riamusica.org/171-ofrezcan-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/171-Ofrezcan-a-Dios.mp3"},
    {id:172, link1:"https://riamusica.org/172-tu-eres-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/172-Tu-eres-santo.mp3"},
    {id:173, link1:"https://riamusica.org/173-te-damos-oh-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/173-Te-damos-oh-Señor.mp3"},
    {id:174, link1:"https://riamusica.org/174-cristo-jesus-siendo-igual-a-dios-filipenses-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/174-Cristo-Jesús-siendo-igual-a-Dios-Filipenses-2.mp3"},
    {id:175, link1:"https://riamusica.org/175-vamos-a-exaltar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/175-Vamos-a-exaltar.mp3"},
    {id:176, link1:"https://riamusica.org/176-encuentro-paz-en-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/176-Encuentro-paz-en-el-Señor.mp3"},
    {id:177, link1:"https://riamusica.org/177-ven-espiritu-divino/", link2:"https://riamusica.org/wp-content/uploads/2018/06/177-Ven-Espíritu-Divino.mp3"},
    {id:178, link1:"https://riamusica.org/178-jerusalen/", link2:"https://riamusica.org/wp-content/uploads/2018/06/178-Jerusalen.mp3"},
    {id:179, link1:"https://riamusica.org/179-no-esta-en-el-numero-tu-fuerza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/179-No-está-en-el-número-tu-fuerza.mp3"},
    {id:180, link1:"https://riamusica.org/180-todo-es-tuyo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/180-Todo-es-tuyo.mp3"},
    {id:181, link1:"https://riamusica.org/181-oh-senor-voy-a-cantar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/181-Oh-Señor-voy-a-cantar.mp3"},
    {id:182, link1:"https://riamusica.org/182-aqui-estoy-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/182-Aquí-estoy-Señor.mp3"},
    {id:183, link1:"https://riamusica.org/183-oh-senor-mi-redentor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/183-Oh-Señor-mi-Redentor.mp3"},
    {id:184, link1:"https://riamusica.org/184-dichoso-aquel-que-confia-en-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/184-Dichoso-aquel-que-confia-en-Dios.mp3"},
    {id:185, link1:"https://riamusica.org/185-dios-no-se-dejo-superar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/185-Dios-no-se-dejó-superar.mp3"},
    {id:186, link1:"https://riamusica.org/186-oh-exaltemos-juntos-siempre-su-nombre-salmo-34/", link2:"https://riamusica.org/wp-content/uploads/2018/06/186-Oh-Exaltemos-juntos-siempre-su-nombre-Salmo-34.mp3"},
    {id:187, link1:"https://riamusica.org/187-alabad-a-yahveh/", link2:"https://riamusica.org/wp-content/uploads/2018/06/187-Alabad-a-Yahveh.mp3"},
    {id:188, link1:"https://riamusica.org/188-a-tu-presencia-senor-entramos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/188-A-tu-presencia-Señor-entramos.mp3"},
    {id:189, link1:"https://riamusica.org/189-fuego-purificador/", link2:"https://riamusica.org/wp-content/uploads/2018/06/189-Fuego-purificador.mp3"},
    {id:190, link1:"https://riamusica.org/190-gracia-redentora/", link2:"https://riamusica.org/wp-content/uploads/2018/06/190-Gracia-Redentora.mp3"},
    {id:191, link1:"https://riamusica.org/191-hasta-que-contemples-tu-hogar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/191-Hasta-que-contemples-tu-hogar.mp3"},
    {id:192, link1:"https://riamusica.org/192-himno-al-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/192-HImno-al-cielo.mp3"},
    {id:193, link1:"https://riamusica.org/193-ilumina-oh-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/193-Ilumina-Oh-Señor.mp3"},
    {id:194, link1:"https://riamusica.org/194-jerusalen-del-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/194-Jerusalén-del-cielo.mp3"},
    {id:195, link1:"https://riamusica.org/195-oh-morada-celestial/", link2:"https://riamusica.org/wp-content/uploads/2018/06/195-Oh-Morada-Celestial.mp3"},
    {id:196, link1:"https://riamusica.org/196-te-pertenezco-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/196-Te-pertenezco-Señor.mp3"},
    {id:197, link1:"https://riamusica.org/197-tu-eres-rey-de-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/197-Tu-eres-Rey-de-Gloria.mp3"},
    {id:198, link1:"https://riamusica.org/198-tuyo-soy-y-tu-eres-mio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/198-Tuyo-soy-y-Tu-eres-mío.mp3"},
    {id:199, link1:"https://riamusica.org/199-ven-santo-espiritu/", link2:"https://riamusica.org/wp-content/uploads/2018/06/199-Ven-Santo-Espíritu.mp3"},
    {id:200, link1:"https://riamusica.org/200-senor-mi-dios-ensename-a-ser-generoso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/200-Señor-mi-Dios-enséñame-a-ser-generoso.mp3"},
    {id:201, link1:"https://riamusica.org/201-pueblo-de-mi-propiedad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/201-Pueblo-de-mi-Propiedad.mp3"},
    {id:202, link1:"https://riamusica.org/202-toma-senor-y-recibe-toda-mi-libertad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/202-Toma-Señor-y-recibe-toda-mi-libertad.mp3"},
    {id:203, link1:"https://riamusica.org/203-tu-amor-es-mejor-que-la-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/203-Tu-amor-es-mejor-que-la-vida.mp3"},
    {id:204, link1:"https://riamusica.org/204-regresa-oh-israel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/204-Regresa-Oh-Israel.mp3"},
    {id:205, link1:"https://riamusica.org/205-manda-el-fuego/", link2:"https://riamusica.org/wp-content/uploads/2018/06/205-Manda-el-fuego.mp3"},
    {id:206, link1:"https://riamusica.org/206-alabado-seas-tu-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/206-Alabado-Seas-tu-Señor.mp3"},
    {id:207, link1:"https://riamusica.org/207-entrare/", link2:"https://riamusica.org/wp-content/uploads/2018/06/207-Entraré.mp3"},
    {id:208, link1:"https://riamusica.org/208-tierra-santa/", link2:"https://riamusica.org/wp-content/uploads/2018/06/208-Tierra-Santa.mp3"},
    {id:209, link1:"https://riamusica.org/209-en-jesus-puse-toda-mi-esperanza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/209-En-Jesús-puse-toda-mi-Esperanza.mp3"},
    {id:210, link1:"https://riamusica.org/210-alma-bendice-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/210-Alma-Bendice-al-Señor.mp3"},
    {id:211, link1:"https://riamusica.org/211-el-senor-de-la-alianza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/211-El-Señor-de-la-Alianza.mp3"},
    {id:212, link1:"https://riamusica.org/212-el-rey-vendra/", link2:"https://riamusica.org/wp-content/uploads/2018/06/212-El-Rey-vendrá.mp3"},
    {id:213, link1:"https://riamusica.org/213-el-senor-resucito/", link2:"https://riamusica.org/wp-content/uploads/2018/06/213-El-Señor-resucitó.mp3"},
    {id:214, link1:"https://riamusica.org/214-y-contemplar-tu-faz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/214-Y-Contemplar-Tu-Faz.mp3"},
    {id:215, link1:"https://riamusica.org/215-tarde-te-ame/", link2:"https://riamusica.org/wp-content/uploads/2018/06/215-Tarde-Te-Amé.mp3"},
    {id:215, link1:"https://riamusica.org/466-tarde-te-ame/", link2:"https://riamusica.org/wp-content/uploads/2018/06/466-Tarde-te-ame.mp3"},
    {id:216, link1:"https://riamusica.org/216-coronad-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/216-Coronad-al-Señor.mp3"},
    {id:217, link1:"https://riamusica.org/217-siempre-agradecido/", link2:"https://riamusica.org/wp-content/uploads/2018/06/217-Siempre-Agradecido.mp3"},
    {id:218, link1:"https://riamusica.org/218-confiare-en-yahve-toda-mi-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/218-Confiaré-en-Yahvé-toda-mi-vida.mp3"},
    {id:219, link1:"https://riamusica.org/219-al-fin-llegara/", link2:"https://riamusica.org/wp-content/uploads/2018/06/219-Al-fin-llegará.mp3"},
    {id:220, link1:"https://riamusica.org/220-jesucristo-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/220-Jesucristo-El-Señor.mp3"},
    {id:221, link1:"https://riamusica.org/221-cristo-es-el-principio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/221-Cristo-es-el-Principio.mp3"},
    {id:222, link1:"https://riamusica.org/222-tu-eres-mi-todo-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/222-Tu-eres-mi-todo-Señor.mp3"},
    {id:223, link1:"https://riamusica.org/223-padre-de-lo-creado/", link2:"https://riamusica.org/wp-content/uploads/2018/06/223-Padre-de-lo-creado.mp3"},
    {id:224, link1:"https://riamusica.org/224-cantare-al-senor-por-siempre-popurri/", link2:"https://riamusica.org/wp-content/uploads/2018/06/224-Cantaré-al-Señor-por-siempre-Popurrí.mp3"},
    {id:225, link1:"https://riamusica.org/225-despierta-alma-mia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/225-Despierta-Alma-Mía.mp3"},
    {id:226, link1:"https://riamusica.org/226-al-contemplar-tu-rostro-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/226-Al-Contemplar-tu-Rostro-Señor.mp3"},
    {id:227, link1:"https://riamusica.org/227-tomame-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/227-Tómame-Señor.mp3"},
    {id:228, link1:"https://riamusica.org/228-habla-tu-siervo-escucha/", link2:"https://riamusica.org/wp-content/uploads/2018/06/228-Habla-tu-Siervo-Escucha.mp3"},
    {id:229, link1:"https://riamusica.org/229-ven-y-derramate/", link2:"https://riamusica.org/wp-content/uploads/2018/06/229-Ven-y-derrámate.mp3"},
    {id:230, link1:"https://riamusica.org/230-ya-no-soy-mio-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/230-Ya-no-soy-mío-Señor.mp3"},
    {id:231, link1:"https://riamusica.org/231-al-creador-de-la-luz-version-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/231-Al-Creador-de-la-Luz-version-2.mp3"},
    {id:232, link1:"https://riamusica.org/232-pueblo-santo-ven-y-adora-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/232-Pueblo-Santo-Ven-y-Adora-2.mp3"},
    {id:233, link1:"https://riamusica.org/233-salmo-103/", link2:"https://riamusica.org/wp-content/uploads/2018/06/233-Salmo-103.mp3"},
    {id:234, link1:"https://riamusica.org/234-te-adorare/", link2:"https://riamusica.org/wp-content/uploads/2018/06/234-Te-adoraré.mp3"},
    {id:235, link1:"https://riamusica.org/235-mi-corazon-mi-mente/", link2:"https://riamusica.org/wp-content/uploads/2018/06/235-Mi-corazón-mi-mente.mp3"},
    {id:236, link1:"https://riamusica.org/236-renuevame/", link2:"https://riamusica.org/wp-content/uploads/2018/06/236-Renuévame.mp3"},
    {id:237, link1:"https://riamusica.org/237-senor-tu-eres-mi-dios-salmo-63/", link2:"https://riamusica.org/wp-content/uploads/2018/06/237-Señor-tu-eres-mi-DIos-Salmo-63.mp3"},
    {id:238, link1:"https://riamusica.org/238-adelante/", link2:"https://riamusica.org/wp-content/uploads/2018/06/238-Adelante.mp3"},
    {id:239, link1:"https://riamusica.org/239-ha-vencido-el-cordero/", link2:"https://riamusica.org/wp-content/uploads/2018/06/239-Ha-Vencido-El-Cordero.mp3"},
    {id:240, link1:"https://riamusica.org/240-ora-en-mi-espiritu-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/240-Ora-en-mí-Espíritu-Santo.mp3"},
    {id:240, link1:"https://riamusica.org/429-ora-en-mi-espiritu-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/429-Ora-en-mí-Espíritu-Santo.mp3"},
    {id:241, link1:"https://riamusica.org/241-ve-al-frente/", link2:"https://riamusica.org/wp-content/uploads/2018/06/241-Ve-al-Frente.mp3"},
    {id:242, link1:"https://riamusica.org/242-mi-herencia-es-el-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/242-Mi-herencia-es-el-Señor.mp3"},
    {id:243, link1:"https://riamusica.org/243-mis-labios-te-alabaran/", link2:"https://riamusica.org/wp-content/uploads/2018/06/243-Mis-labios-te-alabarán.mp3"},
    {id:243, link1:"https://riamusica.org/476-mis-labios-te-alabaran/", link2:"https://riamusica.org/wp-content/uploads/2018/06/476-Mis-labios-te-alabaran.mp3"},
    {id:244, link1:"https://riamusica.org/244-viva-cristo-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/244-¡Viva-Cristo-Rey.mp3"},
    {id:245, link1:"https://riamusica.org/245-toda-la-tierra-aclame-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/245-Toda-la-Tierra-Aclame-al-Señor.mp3"},
    {id:246, link1:"https://riamusica.org/246-salmo-84/", link2:"https://riamusica.org/wp-content/uploads/2018/06/246-Salmo-84.mp3"},
    {id:247, link1:"https://riamusica.org/247-canto-de-victoria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/247-Canto-de-victoria.mp3"},
    {id:248, link1:"https://riamusica.org/248-la-casa-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/248-La-casa-de-DIos.mp3"},
    {id:248, link1:"https://riamusica.org/427-la-casa-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/427-La-casa-de-Dios.mp3"},
    {id:249, link1:"https://riamusica.org/249-la-hora-ha-llegado/", link2:"https://riamusica.org/wp-content/uploads/2018/06/249-La-hora-ha-llegado.mp3"},
    {id:250, link1:"https://riamusica.org/250-acercate/", link2:"https://riamusica.org/wp-content/uploads/2018/06/250-Acércate.mp3"},
    {id:250, link1:"https://riamusica.org/485-acercate/", link2:"https://riamusica.org/wp-content/uploads/2018/06/485-Acercate.mp3"},
    {id:251, link1:"https://riamusica.org/251-heme-aqui-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/251-Heme-aquí-Señor.mp3"},
    {id:251, link1:"https://riamusica.org/473-heme-aqui/", link2:"https://riamusica.org/wp-content/uploads/2018/06/473-Heme-aquí.mp3"},
    {id:252, link1:"https://riamusica.org/252-el-rio-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/252-El-río-de-Dios.mp3"},
    {id:253, link1:"https://riamusica.org/253-entramos-ya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/253-Entramos-ya.mp3"},
    {id:253, link1:"https://riamusica.org/484-entramos-ya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/484-Entramos-ya.mp3"},
    {id:254, link1:"https://riamusica.org/474-la-voz-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/474-La-voz-del-señor.mp3"},
    {id:254, link1:"https://riamusica.org/254-la-voz-del-senor-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/254-La-voz-del-Señor-2.mp3"},
    {id:255, link1:"https://riamusica.org/255-vivimos-en-ti-celebrandote/", link2:"https://riamusica.org/wp-content/uploads/2018/06/255-Vivimos-en-ti-celebrandote.mp3"},
    {id:255, link1:"https://riamusica.org/495-vivimos-por-ti-celebrandote/", link2:"https://riamusica.org/wp-content/uploads/2018/06/495-Vivimos-por-ti-celebrandote.mp3"},
    {id:256, link1:"https://riamusica.org/256-del-cielo-se-oye-una-voz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/256-Del-cielo-se-oye-una-voz.mp3"},
    {id:257, link1:"https://riamusica.org/257-con-alegria-nos-unimos-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/257-Con-alegría-nos-unimos-a-tí.mp3"},
    {id:258, link1:"https://riamusica.org/258-yo-soy-tu-siervo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/258-Yo-soy-tu-siervo.mp3"},
    {id:258, link1:"https://riamusica.org/492-yo-soy-tu-siervo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/492-Yo-soy-tu-siervo.mp3"},
    {id:259, link1:"https://riamusica.org/259-brilla-hoy-tu-antorcha/", link2:"https://riamusica.org/wp-content/uploads/2018/06/259-Brilla-hoy-tu-antorcha.mp3"},
    {id:259, link1:"https://riamusica.org/426-brilla-hoy-tu-antorcha/", link2:"https://riamusica.org/wp-content/uploads/2018/06/426-Brilla-hoy-tu-antorcha.mp3"},
    {id:260, link1:"https://riamusica.org/260-aqui-hay-un-muchacho/", link2:"https://riamusica.org/wp-content/uploads/2018/06/260-Aquí-hay-un-Muchacho.mp3"},
    {id:261, link1:"https://riamusica.org/261-el-rio-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/261-El-Río-de-Dios.mp3"},
    {id:262, link1:"https://riamusica.org/262-ahora-es-cuando/", link2:"https://riamusica.org/wp-content/uploads/2018/06/262-Ahora-es-Cuando.mp3"},
    {id:263, link1:"https://riamusica.org/263-ahora-es-nuestra-oportunidad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/263-Ahora-es-Nuestra-Oportunidad.mp3"},
    {id:264, link1:"https://riamusica.org/264-el-tiempo-de-la-verdad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/264-El-Tiempo-de-la-Verdad.mp3"},
    {id:265, link1:"https://riamusica.org/265-morir-para-vivir/", link2:"https://riamusica.org/wp-content/uploads/2018/06/265-Morir-para-Vivir.mp3"},
    {id:266, link1:"https://riamusica.org/266-orar-luchar-y-construir/", link2:"https://riamusica.org/wp-content/uploads/2018/06/266-Orar-Luchar-y-Construir.mp3"},
    {id:267, link1:"https://riamusica.org/267-dios-nos-llama-a-ser-santos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/267-Dios-Nos-Llama-a-Ser-Santos.mp3"},
    {id:268, link1:"https://riamusica.org/268-tiempos-de-gracia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/268-Tiempos-de-Gracia.mp3"},
    {id:269, link1:"https://riamusica.org/269-a-quien-enviare/", link2:"https://riamusica.org/wp-content/uploads/2018/06/269-A-quién-enviaré.mp3"},
    {id:270, link1:"https://riamusica.org/270-el-es-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/270-El-es-Jesús.mp3"},
    {id:271, link1:"https://riamusica.org/271-jeremias/", link2:"https://riamusica.org/wp-content/uploads/2018/06/271-Jeremías.mp3"},
    {id:272, link1:"https://riamusica.org/272-llevame-adelante/", link2:"https://riamusica.org/wp-content/uploads/2018/06/272-Llévame-adelante.mp3"},
    {id:273, link1:"https://riamusica.org/273-levantaras-nuestros-corazones/", link2:"https://riamusica.org/wp-content/uploads/2018/06/273-Levantaras-nuestros-corazones.mp3"},
    {id:273, link1:"https://riamusica.org/453-levantaras-nuestros-corazones/", link2:"https://riamusica.org/wp-content/uploads/2018/06/453-Levantaras-nuestros-corazones.mp3"},
    {id:274, link1:"https://riamusica.org/274-canto-de-san-patricio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/274-Canto-de-San-Patricio.mp3"},
    {id:274, link1:"https://riamusica.org/440-canto-de-san-patricio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/440-Canto-de-San-Patricio.mp3"},
    {id:275, link1:"https://riamusica.org/275-te-alabo-al-despertar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/275-Te-alabo-al-despertar.mp3"},
    {id:276, link1:"https://riamusica.org/276-cristo-vive/", link2:"https://riamusica.org/wp-content/uploads/2018/06/276-Cristo-vive.mp3"},
    {id:277, link1:"https://riamusica.org/277-santo-es-el-salvador/", link2:"https://riamusica.org/wp-content/uploads/2018/06/277-Santo-es-el-Salvador.mp3"},
    {id:277, link1:"https://riamusica.org/469-santo-es-el-salvador/", link2:"https://riamusica.org/wp-content/uploads/2018/06/469-Santo-es-el-Salvador.mp3"},
    {id:278, link1:"https://riamusica.org/278-se-ha-cubierto-de-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/278-Se-ha-cubierto-de-gloria.mp3"},
    {id:278, link1:"https://riamusica.org/459-se-ha-cubierto-de-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/459-Se-ha-cubierto-de-gloria.mp3"},
    {id:279, link1:"https://riamusica.org/279-ven-emmanuel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/279-Ven-Emmanuel.mp3"},
    {id:283, link1:"https://riamusica.org/25-el-senor-es-mi-fuerza/", link2:"https://riamusica.org/wp-content/uploads/2018/06/25-El-Señor-es-mi-Fuerza.mp3"},
    {id:290, link1:"https://riamusica.org/75-dios-nos-ha-sembrado-una-semilla/", link2:"https://riamusica.org/wp-content/uploads/2018/06/75-Dios-nos-ha-sembrado-una-semilla.mp3"},
    {id:291, link1:"https://riamusica.org/88-abba-padre/", link2:"https://riamusica.org/wp-content/uploads/2018/06/88-Abba-Padre.mp3"},
    {id:292, link1:"https://riamusica.org/89-y-los-redimidos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/89-Y-los-redimidos.mp3"},
    {id:298, link1:"https://riamusica.org/01a-regreso-del-pueblo-a-sion/", link2:"https://riamusica.org/wp-content/uploads/2018/06/01A-Regreso-del-pueblo-a-Sion.mp3"},
    {id:299, link1:"https://riamusica.org/501-senor-ten-piedad-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/501-Señor-ten-piedad-1.mp3"},
    {id:300, link1:"https://riamusica.org/10a-confio-en-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/10A-Confio-en-Dios.mp3"},
    {id:300, link1:"https://riamusica.org/486-confia-en-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/486-Confia-en-Dios.mp3"},
    {id:301, link1:"https://riamusica.org/11a-me-consagro-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/11A-Me-Consagro-a-Tí.mp3"},
    {id:301, link1:"https://riamusica.org/407-me-consagro-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/407-Me-consagro-a-Tí.mp3"},
    {id:302, link1:"https://riamusica.org/12a-mi-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/12A-Mi-Señor.mp3"},
    {id:302, link1:"https://riamusica.org/408-mi-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/408-Mi-Señor.mp3"},
    {id:303, link1:"https://riamusica.org/13a-mi-dios-mi-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/13A-Mi-Dios-Mi-Rey.mp3"},
    {id:304, link1:"https://riamusica.org/14a-talita-kum/", link2:"https://riamusica.org/wp-content/uploads/2018/06/14A-Talita-Kum.mp3"},
    {id:305, link1:"https://riamusica.org/15a-en-marcha/", link2:"https://riamusica.org/wp-content/uploads/2018/06/15A-En-marcha.mp3"},
    {id:306, link1:"https://riamusica.org/16a-perdoname-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/16A-Perdoname-Señor.mp3"},
    {id:307, link1:"https://riamusica.org/17a-el-pueblo-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/17A-El-pueblo-de-Dios.mp3"},
    {id:308, link1:"https://riamusica.org/18a-el-senor-es-el-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/18A-El-Senor-es-el-rey.mp3"},
    {id:308, link1:"https://riamusica.org/cantos-nuevos/el-senor-es-rey/", link2:"https://riamusica.org/wp-content/uploads/2019/06/EL-SEÑOR-ES-REY.mp3"},
    {id:309, link1:"https://riamusica.org/19a-alaben-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/19A-Alaben-a-Dios.mp3"},
    {id:310, link1:"https://riamusica.org/02a-toma-oh-dios-mi-corazon/", link2:"https://riamusica.org/wp-content/uploads/2018/06/02A-Toma-oh-Dios-mi-corazon.mp3"},
    {id:311, link1:"https://riamusica.org/511-aleluya-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/511-Aleluya-1.mp3"},
    {id:312, link1:"https://riamusica.org/20a-vamos-a-entrar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/20A-Vamos-a-entrar.mp3"},
    {id:313, link1:"https://riamusica.org/21a-con-cristo-estoy/", link2:"https://riamusica.org/wp-content/uploads/2018/06/21A-Con-Cristo-estoy.mp3"},
    {id:314, link1:"https://riamusica.org/22a-canten-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/22A-Canten-a-Dios.mp3"},
    {id:315, link1:"https://riamusica.org/23a-ofrenda-al-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/23A-Ofrenda-al-amor.mp3"},
    {id:316, link1:"https://riamusica.org/24a-su-luz-nos-mostro/", link2:"https://riamusica.org/wp-content/uploads/2018/06/24A-Su-luz-nos-mostró.mp3"},
    {id:316, link1:"https://riamusica.org/455-su-luz-nos-mostro/", link2:"https://riamusica.org/wp-content/uploads/2018/06/455-Su-luz-nos-mostró.mp3"},
    {id:318, link1:"https://riamusica.org/26a-seguire/", link2:"https://riamusica.org/wp-content/uploads/2018/06/26A-Seguiré.mp3"},
    {id:319, link1:"https://riamusica.org/27a-toma-mi-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/27A-Toma-mi-vida.mp3"},
    {id:319, link1:"https://riamusica.org/438-toma-mi-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/438-Toma-mi-vida.mp3"},
    {id:320, link1:"https://riamusica.org/28a-sumo-sacerdote/", link2:"https://riamusica.org/wp-content/uploads/2018/06/28A-Sumo-Sacerdote.mp3"},
    {id:320, link1:"https://riamusica.org/478-sumo-sacerdote/", link2:"https://riamusica.org/wp-content/uploads/2018/06/478-Sumo-Sacerdote.mp3"},
    {id:321, link1:"https://riamusica.org/29a-aleluya-tu-eres-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/29A-Aleluya-Tu-eres-Rey.mp3"},
    {id:321, link1:"https://riamusica.org/462-aleluya-tu-eres-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/462-Aleluya-Tu-Eres-Rey.mp3"},
    {id:322, link1:"https://riamusica.org/03a-que-los-santos-se-regocijen/", link2:"https://riamusica.org/wp-content/uploads/2018/06/03A-Que-los-santos-se-regocijen.mp3"},
    {id:322, link1:"https://riamusica.org/463-que-los-santos-se-regocijen/", link2:"https://riamusica.org/wp-content/uploads/2018/06/463-Que-los-santos-se-regocijen.mp3"},
    {id:324, link1:"https://riamusica.org/30a-en-tu-presencia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/30A-En-Tu-Presencia.mp3"},
    {id:325, link1:"https://riamusica.org/31a-reina-en-lo-alto/", link2:"https://riamusica.org/wp-content/uploads/2018/06/31A-Reina-en-lo-alto.mp3"},
    {id:326, link1:"https://riamusica.org/32a-salvador-del-mundo-ven/", link2:"https://riamusica.org/wp-content/uploads/2018/06/32A-Salvador-del-mundo-Ven.mp3"},
    {id:327, link1:"https://riamusica.org/33a-el-leon-de-juda/", link2:"https://riamusica.org/wp-content/uploads/2018/06/33A-El-león-de-Judá.mp3"},
    {id:328, link1:"https://riamusica.org/34a-senor-tu-tienes-palabras-de-vida-eterna/", link2:"https://riamusica.org/wp-content/uploads/2018/06/34A-Señor-tu-tienes-palabras-de-vida-eterna.mp3"},
    {id:329, link1:"https://riamusica.org/35a-victoria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/35A-Victoria.mp3"},
    {id:330, link1:"https://riamusica.org/36a-tiende-tu-oido-senor-respondeme/", link2:"https://riamusica.org/wp-content/uploads/2018/06/36A-Tiende-tu-oido-Senor-respondeme.mp3"},
    {id:331, link1:"https://riamusica.org/37a-eres-la-llama-encendida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/37A-Eres-la-llama-encendida.mp3"},
    {id:332, link1:"https://riamusica.org/38a-regocijese-pueblo-entero/", link2:"https://riamusica.org/wp-content/uploads/2018/06/38A-Regocijese-Pueblo-Entero.mp3"},
    {id:333, link1:"https://riamusica.org/39a-salmo-131/", link2:"https://riamusica.org/wp-content/uploads/2018/06/39A-Salmo-131.mp3"},
    {id:333, link1:"https://riamusica.org/489-salmo-131/", link2:"https://riamusica.org/wp-content/uploads/2018/06/489-Salmo-131.mp3"},
    {id:334, link1:"https://riamusica.org/04a-majestad-gloriosa/", link2:"https://riamusica.org/wp-content/uploads/2018/06/04A-Majestad-gloriosa.mp3"},
    {id:335, link1:"https://riamusica.org/521-hemos-entregado-nuestras-vidas-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/521-Hemos-entregado-nuestras-vidas-al-Señor.mp3"},
    {id:337, link1:"https://riamusica.org/41a-la-puerta-del-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/41A-La-puerta-del-cielo.mp3"},
    {id:338, link1:"https://riamusica.org/42a-el-senor-es-mi-luz-salmo-26/", link2:"https://riamusica.org/wp-content/uploads/2018/06/42A-El-Señor-es-mi-luz-salmo-26.mp3"},
    {id:339, link1:"https://riamusica.org/43a-venga-aclamemos-de-jubilo-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/43A-Venga-aclamemos-de-júbilo-al-Señor.mp3"},
    {id:340, link1:"https://riamusica.org/44a-donde-esta-oh-muerte-tu-victoria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/44A-Donde-esta-oh-muerte-tu-victoria.mp3"},
    {id:341, link1:"https://riamusica.org/45a-la-batalla-espiritual/", link2:"https://riamusica.org/wp-content/uploads/2018/06/45A-La-batalla-espiritual.mp3"},
    {id:342, link1:"https://riamusica.org/46a-esperare-en-silencio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/46A-Esperare-en-Silencio.mp3"},
    {id:344, link1:"https://riamusica.org/48a-revistenos-de-tu-santidad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/48A-Revístenos-de-tu-santidad.mp3"},
    {id:345, link1:"https://riamusica.org/49a-nueva-criatura/", link2:"https://riamusica.org/wp-content/uploads/2018/06/49A-Nueva-criatura.mp3"},
    {id:346, link1:"https://riamusica.org/05a-dia-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/05A-Día-del-Señor.mp3"},
    {id:348, link1:"https://riamusica.org/50a-aleluya-cantad-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/50A-Aleluya-cantad-al-Señor.mp3"},
    {id:349, link1:"https://riamusica.org/51a-mi-vida-entrego-a-ti/", link2:"https://riamusica.org/wp-content/uploads/2018/06/51A-Mi-vida-entrego-a-ti.mp3"},
    {id:350, link1:"https://riamusica.org/52a-llename/", link2:"https://riamusica.org/wp-content/uploads/2018/06/52A-Lléname.mp3"},
    {id:351, link1:"https://riamusica.org/53a-mantente-fiel-hasta-la-muerte/", link2:"https://riamusica.org/wp-content/uploads/2018/06/53A-Mantente-fiel-hasta-la-muerte.mp3"},
    {id:351, link1:"https://riamusica.org/498-mantente-fiel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/498-Mantente-Fiel.mp3"},
    {id:353, link1:"https://riamusica.org/55a-santo-y-poderoso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/55A-Santo-y-Poderoso.mp3"},
    {id:353, link1:"https://riamusica.org/450-santo-y-poderoso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/450-Santo-y-poderoso.mp3"},
    {id:354, link1:"https://riamusica.org/56a-ven-e-ilumina/", link2:"https://riamusica.org/wp-content/uploads/2018/06/56A-Ven-e-ilumina.mp3"},
    {id:355, link1:"https://riamusica.org/57a-te-doy-mi-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/57A-Te-doy-mi-vida.mp3"},
    {id:356, link1:"https://riamusica.org/58a-canten-las-naciones/", link2:"https://riamusica.org/wp-content/uploads/2018/06/58A-Canten-las-naciones.mp3"},
    {id:357, link1:"https://riamusica.org/59a-senor-reina-en-mi/", link2:"https://riamusica.org/wp-content/uploads/2018/06/59A-Señor-Reina-en-Mí.mp3"},
    {id:358, link1:"https://riamusica.org/06a-canten-gloria-en-el-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/06A-Canten-gloria-en-el-cielo.mp3"},
    {id:358, link1:"https://riamusica.org/604-canten-gloria-en-el-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/604-Canten-gloria-en-el-cielo.mp3"},
    {id:359, link1:"https://riamusica.org/535-cordero-de-dios-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/535-Cordero-de-Dios-1.mp3"},
    {id:360, link1:"https://riamusica.org/60a-cristo-ya-resucito/", link2:"https://riamusica.org/wp-content/uploads/2018/06/60A-Cristo-ya-resucitó.mp3"},
    {id:361, link1:"https://riamusica.org/61a-escucha-israel/", link2:"https://riamusica.org/wp-content/uploads/2018/06/61A-Escucha-Israel.mp3"},
    {id:362, link1:"https://riamusica.org/62a-tu-eres-la-fuente-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/62A-Tu-eres-la-fuente-Señor.mp3"},
    {id:363, link1:"https://riamusica.org/63a-haznos-tuyos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/63A-Haznos-Tuyos.mp3"},
    {id:364, link1:"https://riamusica.org/64a-abreme-que-quiero-entrar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/64A-Abreme-que-quiero-entrar.mp3"},
    {id:365, link1:"https://riamusica.org/402-canto-ante-el-trono/", link2:"https://riamusica.org/wp-content/uploads/2018/06/402-Canto-ante-el-trono.mp3"},
    {id:366, link1:"https://riamusica.org/548-theotokos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/548-Theotokos.mp3"},
    {id:367, link1:"https://riamusica.org/08a-el-santuario-de-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/08A-El-Santuario-de-Dios.mp3"},
    {id:368, link1:"https://riamusica.org/09a-queremos-seguirte-oh-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/09A-Queremos-Seguirte-Oh-Dios.mp3"},
    {id:369, link1:"https://riamusica.org/401-somos-uno-es-espiritu/", link2:"https://riamusica.org/wp-content/uploads/2018/06/401-Somos-uno-es-Espiritu.mp3"},
    {id:370, link1:"https://riamusica.org/403-gracias-a-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/403-Gracias-a-Dios.mp3"},
    {id:371, link1:"https://riamusica.org/404-fija-tus-ojos-en-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/404-Fija-tus-ojos-en-Cristo.mp3"},
    {id:372, link1:"https://riamusica.org/405-yo-tengo-gozo-en-mi-alma/", link2:"https://riamusica.org/wp-content/uploads/2018/06/405-Yo-tengo-gozo-en-mi-alma.mp3"},
    {id:372, link1:"https://riamusica.org/406-su-nombre-es-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/406-Su-nombre-es-Jesus.mp3"},
    {id:374, link1:"https://riamusica.org/409-dios-descendio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/409-Dios-descendió.mp3"},
    {id:375, link1:"https://riamusica.org/410-arde-en-mi/", link2:"https://riamusica.org/wp-content/uploads/2018/06/410-Arde-en-mi.mp3"},
    {id:376, link1:"https://riamusica.org/411-que-grande-es-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/411-Que-grande-es-mi-Dios.mp3"},
    {id:377, link1:"https://riamusica.org/412-fluira-como-rio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/412-Fluira-como-rio.mp3"},
    {id:378, link1:"https://riamusica.org/413-a-nada-temere/", link2:"https://riamusica.org/wp-content/uploads/2018/06/413-A-nada-temeré.mp3"},
    {id:379, link1:"https://riamusica.org/414-que-quiero-mi-jesus/", link2:"https://riamusica.org/wp-content/uploads/2018/06/414-Qué-quiero-mi-Jesús.mp3"},
    {id:380, link1:"https://riamusica.org/415-espiritu-del-dios-de-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/415-Espiritu-del-Dios-de-amor.mp3"},
    {id:381, link1:"https://riamusica.org/416-jesus-jesus-de-nazaret/", link2:"https://riamusica.org/wp-content/uploads/2018/06/416-Jesus-Jesus-de-Nazaret.mp3"},
    {id:382, link1:"https://riamusica.org/417-salmo-121/", link2:"https://riamusica.org/wp-content/uploads/2018/06/417-Salmo-121.mp3"},
    {id:383, link1:"https://riamusica.org/419-senor-mi-dios-al-contemplar-los-cielos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/419-Señor-mi-Dios-al-contemplar-los-cielos.mp3"},
    {id:384, link1:"https://riamusica.org/420-para-corresponder/", link2:"https://riamusica.org/wp-content/uploads/2018/06/420-Para-corresponder.mp3"},
    {id:385, link1:"https://riamusica.org/421-tal-como-soy/", link2:"https://riamusica.org/wp-content/uploads/2018/06/421-Tal-como-soy.mp3"},
    {id:385, link1:"https://riamusica.org/423-oh-cristo-vencedor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/423-Oh-Cristo-Vencedor.mp3"},
    {id:387, link1:"https://riamusica.org/424-gloria-al-rey/", link2:"https://riamusica.org/wp-content/uploads/2018/06/424-Gloria-al-Rey.mp3"},
    {id:388, link1:"https://riamusica.org/428-el-manto-de-tu-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/428-El-manto-de-tu-gloria.mp3"},
    {id:389, link1:"https://riamusica.org/430-salmo-48/", link2:"https://riamusica.org/wp-content/uploads/2018/06/430-Salmo-48.mp3"},
    {id:390, link1:"https://riamusica.org/431-bendito-sea-el-senor-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/431-Bendito-sea-el-Señor-mi-Dios.mp3"},
    {id:391, link1:"https://riamusica.org/432-el-ejercito-del-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/432-El-ejército-del-Señor.mp3"},
    {id:391, link1:"https://riamusica.org/ejercito/", link2:"https://riamusica.org/wp-content/uploads/2018/06/ejercito.mp3"},
    {id:392, link1:"https://riamusica.org/433-himno-pascual/", link2:"https://riamusica.org/wp-content/uploads/2018/06/433-Himno-Pascual.mp3"},
    {id:393, link1:"https://riamusica.org/434-digno-el-cordero-que-fue-inmolado/", link2:"https://riamusica.org/wp-content/uploads/2018/06/434-Digno-el-cordero-que-fue-inmolado.mp3"},
    {id:394, link1:"https://riamusica.org/436-adoremos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/436-Adoremos.mp3"},
    {id:395, link1:"https://riamusica.org/437-cristo-rey-y-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/437-Cristo-Rey-y-Señor.mp3"},
    {id:396, link1:"https://riamusica.org/439-tu-eres-mi-hijo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/439-Tu-eres-mi-hijo.mp3"},
    {id:397, link1:"https://riamusica.org/441-cautivame-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/441-Cautívame-Señor.mp3"},
    {id:398, link1:"https://riamusica.org/442-todo-tu-pueblo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/442-Todo-tu-pueblo.mp3"},
    {id:399, link1:"https://riamusica.org/443-a-ti-la-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/443-A-ti-la-gloria.mp3"},
    {id:400, link1:"https://riamusica.org/444-gloria-martin-valverde/", link2:"https://riamusica.org/wp-content/uploads/2018/06/444-Gloria-Martìn-Valverde.mp3"},
    {id:401, link1:"https://riamusica.org/448-sublime-gracia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/448-Sublime-Gracia.mp3"},
    {id:402, link1:"https://riamusica.org/449-bendito-espiritu-santo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/449-Bendito-Espíritu-Santo.mp3"},
    {id:403, link1:"https://riamusica.org/451-por-siempre-alabarte/", link2:"https://riamusica.org/wp-content/uploads/2018/06/451-Por-siempre-alabarte.mp3"},
    {id:404, link1:"https://riamusica.org/452-que-amables-tus-moradas/", link2:"https://riamusica.org/wp-content/uploads/2018/06/452-Qué-amables-tus-moradas.mp3"},
    {id:405, link1:"https://riamusica.org/454-hosanna/", link2:"https://riamusica.org/wp-content/uploads/2018/06/454-Hosanna.mp3"},
    {id:406, link1:"https://riamusica.org/456-glorioso-cristo-rey-de-lo-creado/", link2:"https://riamusica.org/wp-content/uploads/2018/06/456-Glorioso-Cristo-Rey-de-lo-creado.mp3"},
    {id:407, link1:"https://riamusica.org/458-por-la-exelsa-majestad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/458-Por-la-exelsa-majestad.mp3"},
    {id:408, link1:"https://riamusica.org/460-aleluya-su-agape-aqui-esta/", link2:"https://riamusica.org/wp-content/uploads/2018/06/460-Aleluya-su-agape-aqui-esta.mp3"},
    {id:409, link1:"https://riamusica.org/461-profecia-del-arbol/", link2:"https://riamusica.org/wp-content/uploads/2018/06/461-Profecía-del-Arbol.mp3"},
    {id:410, link1:"https://riamusica.org/464-vengo-a-tu-presencia/", link2:"https://riamusica.org/wp-content/uploads/2018/06/464-Vengo-a-tu-presencia.mp3"},
    {id:411, link1:"https://riamusica.org/465-gloria-al-senor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/465-Gloria-al-Señor.mp3"},
    {id:412, link1:"https://riamusica.org/467-nadie-como-tu/", link2:"https://riamusica.org/wp-content/uploads/2018/06/467-Nadie-como-tu.mp3"},
    {id:413, link1:"https://riamusica.org/468-por-tu-gran-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/468-Por-tu-gran-amor.mp3"},
    {id:414, link1:"https://riamusica.org/470-eres-todopoderoso/", link2:"https://riamusica.org/wp-content/uploads/2018/06/470-Eres-todopoderoso.mp3"},
    {id:415, link1:"https://riamusica.org/471-en-tu-presencia-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/471-En-tu-presencia-2.mp3"},
    {id:416, link1:"https://riamusica.org/475-en-espiritu-para-adorar/", link2:"https://riamusica.org/wp-content/uploads/2018/06/475-En-espiritu-para-adorar.mp3"},
    {id:417, link1:"https://riamusica.org/477-mi-paz-te-doy/", link2:"https://riamusica.org/wp-content/uploads/2018/06/477-Mi-paz-te-doy.mp3"},
    {id:418, link1:"https://riamusica.org/479-jesucristo-es-mi-bandera/", link2:"https://riamusica.org/wp-content/uploads/2018/06/479-Jesucristo-es-mi-bandera.mp3"},
    {id:419, link1:"https://riamusica.org/480-oh-dios-sol-de-nuestro-amor/", link2:"https://riamusica.org/wp-content/uploads/2018/06/480-Oh-Dios-Sol-de-nuestro-amor.mp3"},
    {id:421, link1:"https://riamusica.org/482-santuario/", link2:"https://riamusica.org/wp-content/uploads/2018/06/482-Santuario.mp3"},
    {id:422, link1:"https://riamusica.org/483-vengan-a-mi/", link2:"https://riamusica.org/wp-content/uploads/2018/06/483-Vengan-a-mí.mp3"},
    {id:424, link1:"https://riamusica.org/487-mi-herencia-senor-eres-tu/", link2:"https://riamusica.org/wp-content/uploads/2018/06/487-Mi-herencia-Señor-eres-Tú.mp3"},
    {id:425, link1:"https://riamusica.org/488-kadosh-kadosh-kadosh/", link2:"https://riamusica.org/wp-content/uploads/2018/06/488-Kadosh-Kadosh-Kadosh.mp3"},
    {id:426, link1:"https://riamusica.org/490-cristo-nos-rescata/", link2:"https://riamusica.org/wp-content/uploads/2018/06/490-Cristo-nos-rescata.mp3"},
    {id:427, link1:"https://riamusica.org/491-fe-esperanza-y-caridad/", link2:"https://riamusica.org/wp-content/uploads/2018/06/491-Fe-esperanza-y-caridad.mp3"},
    {id:428, link1:"https://riamusica.org/493-nuestra-patria-esta-en-el-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/493-Nuestra-patria-está-en-el-cielo.mp3"},
    {id:429, link1:"https://riamusica.org/496-no-pasara/", link2:"https://riamusica.org/wp-content/uploads/2018/06/496-No-pasará.mp3"},
    {id:430, link1:"https://riamusica.org/497-aunque-todos-yo-no/", link2:"https://riamusica.org/wp-content/uploads/2018/06/497-Aunque-todos-yo-no.mp3"},
    {id:431, link1:"https://riamusica.org/499-la-obediencia-es-mi-alegria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/499-La-obediencia-es-mi-alegría.mp3"},
    {id:432, link1:"https://riamusica.org/502-senor-ten-piedad-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/502-Señor-ten-piedad-2.mp3"},
    {id:433, link1:"https://riamusica.org/503-senor-ten-piedad-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/503-Señor-ten-piedad-3.mp3"},
    {id:434, link1:"https://riamusica.org/504-oh-senor-ten-piedad-de-nosotros/", link2:"https://riamusica.org/wp-content/uploads/2018/06/504-Oh-Señor-ten-piedad-de-nosotros.mp3"},
    {id:435, link1:"https://riamusica.org/505-kyrie-eleison/", link2:"https://riamusica.org/wp-content/uploads/2018/06/505-Kyrie-Eleison.mp3"},
    {id:436, link1:"https://riamusica.org/506-kyrie-kyrie/", link2:"https://riamusica.org/wp-content/uploads/2018/06/506-Kyrie-Kyrie.mp3"},
    {id:437, link1:"https://riamusica.org/507-gloria/", link2:"https://riamusica.org/wp-content/uploads/2018/06/507-Gloria.mp3"},
    {id:438, link1:"https://riamusica.org/508-gloria-a-dios-en-el-cielo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/508-Gloria-a-Dios-en-el-Cielo.mp3"},
    {id:438, link1:"https://riamusica.org/509-gloria-a-dios-en-el-cielo-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/509-Gloria-a-Dios-en-el-Cielo-2.mp3"},
    {id:439, link1:"https://riamusica.org/510-gloria-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/510-Gloria-2.mp3"},
    {id:440, link1:"https://riamusica.org/512-aleluya-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/512-Aleluya-2.mp3"},
    {id:441, link1:"https://riamusica.org/513-aleluya-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/513-Aleluya-3.mp3"},
    {id:442, link1:"https://riamusica.org/514-canta-aleluya/", link2:"https://riamusica.org/wp-content/uploads/2018/06/514-Canta-Aleluya.mp3"},
    {id:443, link1:"https://riamusica.org/515-aleluya-el-senor-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/515-Aleluya-el-Señor-mi-Dios.mp3"},
    {id:444, link1:"https://riamusica.org/516-no-solo-de-pan-vive-el-hombre/", link2:"https://riamusica.org/wp-content/uploads/2018/06/516-No-solo-de-pan-vive-el-hombre.mp3"},
    {id:445, link1:"https://riamusica.org/517-en-tu-palabra-senor-medito/", link2:"https://riamusica.org/wp-content/uploads/2018/06/517-En-tu-palabra-Señor-medito.mp3"},
    {id:446, link1:"https://riamusica.org/518-tu-palabra-me-da-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/518-Tu-palabra-me-da-vida.mp3"},
    {id:447, link1:"https://riamusica.org/519-te-presentamos-el-vino-y-el-pan/", link2:"https://riamusica.org/wp-content/uploads/2018/06/519-Te-presentamos-el-vino-y-el-pan.mp3"},
    {id:448, link1:"https://riamusica.org/520-esto-que-te-doy/", link2:"https://riamusica.org/wp-content/uploads/2018/06/520-Esto-que-te-doy.mp3"},
    {id:449, link1:"https://riamusica.org/522-santo-santo-santo-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/522-Santo-Santo-Santo-3.mp3"},
    {id:450, link1:"https://riamusica.org/523-santo-santo-santo-4/", link2:"https://riamusica.org/wp-content/uploads/2018/06/523-Santo-Santo-Santo-4.mp3"},
    {id:451, link1:"https://riamusica.org/525-santo-santo-santo-5/", link2:"https://riamusica.org/wp-content/uploads/2018/06/525-Santo-Santo-Santo-5.mp3"},
    {id:452, link1:"https://riamusica.org/524-santo-santo-santo-6/", link2:"https://riamusica.org/wp-content/uploads/2018/06/524-Santo-Santo-Santo-6.mp3"},
    {id:453, link1:"https://riamusica.org/526-santo-es-el-senor-mi-dios/", link2:"https://riamusica.org/wp-content/uploads/2018/06/526-Santo-es-el-señor-mi-Dios.mp3"},
    {id:454, link1:"https://riamusica.org/527-aclamacion-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/527-Aclamación-1.mp3"},
    {id:454, link1:"https://riamusica.org/527-aclamacion-1-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/527-Aclamación-1-1.mp3"},
    {id:456, link1:"https://riamusica.org/528-aclamacion-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/528-Aclamación-2.mp3"},
    {id:457, link1:"https://riamusica.org/529-aclamcion-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/529-Aclamción-3.mp3"},
    {id:458, link1:"https://riamusica.org/531-aclamacion-5/", link2:"https://riamusica.org/wp-content/uploads/2018/06/531-Aclamación-5.mp3"},
    {id:459, link1:"https://riamusica.org/532-amen-1/", link2:"https://riamusica.org/wp-content/uploads/2018/06/532-Amén-1.mp3"},
    {id:460, link1:"https://riamusica.org/533-amen-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/533-Amén-2.mp3"},
    {id:461, link1:"https://riamusica.org/534-amen-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/534-Amén-3.mp3"},
    {id:462, link1:"https://riamusica.org/536-cordero-de-dios-2/", link2:"https://riamusica.org/wp-content/uploads/2018/06/536-Cordero-de-Dios-2.mp3"},
    {id:463, link1:"https://riamusica.org/537-cordero-de-dios-3/", link2:"https://riamusica.org/wp-content/uploads/2018/06/537-Cordero-de-Dios-3.mp3"},
    {id:464, link1:"https://riamusica.org/538-yo-soy-el-pan-de-vida/", link2:"https://riamusica.org/wp-content/uploads/2018/06/538-Yo-soy-el-pan-de-vida.mp3"},
    {id:465, link1:"https://riamusica.org/539-de-rodillas-partamos-hoy-el-pan/", link2:"https://riamusica.org/wp-content/uploads/2018/06/539-De-rodillas-partamos-hoy-el-pan.mp3"},
    {id:466, link1:"https://riamusica.org/540-quedate-con-nosotros/", link2:"https://riamusica.org/wp-content/uploads/2018/06/540-Quédate-con-nosotros.mp3"},
    {id:467, link1:"https://riamusica.org/541-presencia-y-misterio/", link2:"https://riamusica.org/wp-content/uploads/2018/06/541-Presencia-y-Misterio.mp3"},
    {id:468, link1:"https://riamusica.org/542-quedate/", link2:"https://riamusica.org/wp-content/uploads/2018/06/542-Quédate.mp3"},
    {id:469, link1:"https://riamusica.org/543-alma-de-cristo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/543-Alma-de-Cristo.mp3"},
    {id:471, link1:"https://riamusica.org/545-alma-misionera/", link2:"https://riamusica.org/wp-content/uploads/2018/06/545-Alma-misionera.mp3"},
    {id:472, link1:"https://riamusica.org/546-de-nuevo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/546-De-nuevo.mp3"},
    {id:473, link1:"https://riamusica.org/547-salve-reina-de-los-cielos/", link2:"https://riamusica.org/wp-content/uploads/2018/06/547-Salve-Reina-de-los-cielos.mp3"},
    {id:474, link1:"https://riamusica.org/549-salve-regina/", link2:"https://riamusica.org/wp-content/uploads/2018/06/549-Salve-Regina.mp3"},
    {id:475, link1:"https://riamusica.org/550-oh-reina/", link2:"https://riamusica.org/wp-content/uploads/2018/06/550-Oh-Reina.mp3"},
    {id:476, link1:"https://riamusica.org/551-himno-a-la-cruz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/551-Himno-a-la-cruz.mp3"},
    {id:477, link1:"https://riamusica.org/602-oid-un-son-en-alta-esfera/", link2:"https://riamusica.org/wp-content/uploads/2018/06/602-Oid-un-son-en-alta-esfera.mp3"},
    {id:478, link1:"https://riamusica.org/601-oh-santisimo-felicisimo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/601-Oh-Santisimo-felicisimo.mp3"},
    {id:479, link1:"https://riamusica.org/603-adeste-fideles/", link2:"https://riamusica.org/wp-content/uploads/2018/06/603-Adeste-fideles.mp3"},
    {id:480, link1:"https://riamusica.org/605-noche-de-paz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/605-Noche-de-paz.mp3"},
    {id:481, link1:"https://riamusica.org/606-al-mundo-paz/", link2:"https://riamusica.org/wp-content/uploads/2018/06/606-Al-mundo-paz.mp3"},
    {id:482, link1:"https://riamusica.org/607-oh-noche-santa/", link2:"https://riamusica.org/wp-content/uploads/2018/06/607-Oh-noche-santa.mp3"},
    {id:483, link1:"https://riamusica.org/608-gloria-in-excelsis-deo/", link2:"https://riamusica.org/wp-content/uploads/2018/06/608-Gloria-in-excelsis-Deo.mp3"},
    {id:484, link1:"https://riamusica.org/609-regogijate-belen/", link2:"https://riamusica.org/wp-content/uploads/2018/06/609-Regogijate-Belen.mp3"},
	{id:486, link1:"httpss://riamusica.org/Cantico-de-Simeon/", link2:"httpss://riamusica.org/wp-content/uploads/2020/03/Cantico-de-Simeon.mp3"},
	{id:487, link1:"httpss://riamusica.org/Oracion-de-la-Noche/", link2:"httpss://riamusica.org/wp-content/uploads/2020/03/Oracion-de-la-Noche.mp3"}
];
