import React from "react";

export default function TeclaDerecha() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M 16.50306,137.64054 C 9.94028,135.56758 4.66517,130.90942 1.6865,124.55682 L 0,120.96001 V 69.120008 17.280001 L 1.70266,13.647907 C 4.08651,8.5627 8.01915,4.54585 13.02809,2.079957 L 17.25308,0 h 54.41345 54.41346 l 3.6321,1.70266 c 5.08534,2.383919 9.10206,6.316486 11.56794,11.325586 l 2.07996,4.225146 v 52.173306 52.173312 l -1.82529,3.52 c -2.45023,4.72519 -6.32494,8.64225 -10.97471,11.09468 l -3.84,2.02532 -53.76,0.1262 c -43.25083,0.10153 -54.2872,-0.0403 -56.45693,-0.72567 z M 61.59454,59.045584 c 8.43551,-8.428666 13.60545,-14.069703 13.60545,-14.845198 0,-1.463251 -26.00885,-27.880385 -27.44949,-27.880385 -1.30672,0 -3.2705,1.912205 -3.2705,3.184606 0,0.586422 4.464,5.498607 9.92,10.915967 5.456,5.417361 9.91999,10.073502 9.91999,10.34698 0,0.273479 -11.01599,0.572659 -24.47999,0.664845 l -24.48,0.167603 v 2.56 2.560001 l 24.10736,0.32 c 13.25904,0.176 24.34589,0.558848 24.63744,0.850777 0.29155,0.29193 -4.00477,4.855489 -9.54736,10.141242 -10.64316,10.149959 -11.55345,11.581566 -8.65504,13.611696 0.78232,0.54795 1.57187,0.99629 1.75454,0.99629 0.18268,0 6.45459,-6.117495 13.9376,-13.594424 z"
        fill="#999"
      />
    </svg>
  );
}
