import React, { Component } from "react";

import { withStyles } from "@material-ui/core/styles";
import Slider from "@material-ui/core/Slider";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";
import Tooltip from "@material-ui/core/Tooltip";

import { BrowserView } from "react-device-detect";

import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import PauseIcon from "@material-ui/icons/Pause";
import VolumeUpIcon from "@material-ui/icons/VolumeUp";
import VolumeDownIcon from "@material-ui/icons/VolumeDown";
import VolumeMuteIcon from "@material-ui/icons/VolumeMute";
import VolumeOffIcon from "@material-ui/icons/VolumeOff";
import MusicNoteIcon from "@material-ui/icons/MusicNote";

import ControlVolumen from "./ControlVolumen";

import { linksAudios } from "../Data/linksAudios";

const style = {
  audioPlayerWrapper(hidePlayer) {
    return {
      display: hidePlayer ? "none" : "block"
    };
  }
};

const styles = theme => ({
  sliderRoot: {
    color: "#395479"
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: "#fff",
    border: "2px solid currentColor",
    marginTop: -8,
    marginLeft: -12,
    "&:focus,&:hover,&$active": {
      boxShadow: "inherit"
    }
  },
  active: {},
  valueLabel: {
    left: "calc(-50% + 4px)"
  },
  track: {
    height: 8,
    borderRadius: 4
  },
  rail: {
    height: 8,
    borderRadius: 4
  },
  appBar: {
    top: "auto",
    bottom: 0,
    backgroundColor: "#dddddd"
  },
  fabButton: {
    position: "absolute",
    zIndex: 1,
    top: -30,
    left: 0,
    right: 0,
    margin: "0 auto"
  },
  timeLabel: {
    marginRight: 20,
    marginLeft: 20
  },
  fab: {
    position: "fixed",
    bottom: theme.spacing(3),
    right: theme.spacing(3)
  },
  fab2: {
    position: "fixed",
    bottom: theme.spacing(5),
    right: theme.spacing(3),
    backgroundColor: "#dddddd",
    color: "black"
  }
});

function getTime(time) {
  if (!isNaN(time)) {
    return (
      ("0" + Math.floor(time / 60)).slice(-2) +
      ":" +
      ("0" + Math.floor(time % 60)).slice(-2)
    );
  }
}

let textoBoton = "";

class Reproductor extends Component {
  state = {
    duration: 0,
    currentTime: 0,
    currentVolume: 50,
    isPlaying: false,
    anchorEl: null,
    lastId: 1,
    cargarAudio: false,
  };

  componentDidMount() {
    this.audio.addEventListener("timeupdate", e => {
      this.setState({
        currentTime: e.target.currentTime,
        duration: e.target.duration
      });
    });

    // When audio play starts
    this.audio.addEventListener("play", e => {
      //Cambiar el estado isPlaying a true
      this.setState({ isPlaying: true });
      this.props.onPlay && this.props.onPlay(e);
    });
  }

  componentWillUnmount() {
    this.audio.removeEventListener("timeupdate", () => {});
  }

  pressPlayButton = () => {
    let temp = !this.state.isPlaying;
    this.setState({ isPlaying: temp });
    if (this.audio.paused && this.audio.src) {
      this.audio.play();
    } else if (!this.audio.paused) {
      this.audio.pause();
    }
  };

  togglePlay = () => {
    if (this.audio.paused && this.audio.src) {
      this.audio.play();
    } else if (!this.audio.paused) {
      this.audio.pause();
    }
  };

  toggleShowBar = () => {
    this.props.setShowMusic(!this.props.showMusic)
  };

  sliderValueChange = (e, newValue) => {
    this.audio.currentTime = newValue;
  };

  volumeChangeButton = newVolume => {
    this.setState({ currentVolume: newVolume });
    this.audio.volume = newVolume / 100;
  };

  volumeChangeSlider = (event, newVolume) => {
    this.setState({ currentVolume: newVolume });
    this.audio.volume = newVolume / 100;
  };

  handleClickOpenVolume = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseVolume = () => {
    this.setState({ anchorEl: null });
  };

  drawVolumeIcon = () => {
    if (this.state.currentVolume > 60) {
      return <VolumeUpIcon />;
    } else if (this.state.currentVolume > 25) {
      return <VolumeDownIcon />;
    } else if (this.state.currentVolume > 0) {
      return <VolumeMuteIcon />;
    } else {
      return <VolumeOffIcon />;
    }
  };

  searchSong = songId => {
    let songsList = linksAudios.filter(x => x.id === songId);
    if (songsList[0]) {
      this.src = songsList[0].link2;
      this.disabled = false;
    } else {
      this.disabled = true;
    }
  };

  render() {
    const { children, classes, songID, showMusic} = this.props;
    const {
      currentTime,
      duration,
      isPlaying,
      anchorEl,
      currentVolume,
      lastId
    } = this.state;

    if (lastId !== songID) {
      this.setState({ isPlaying: false,  lastId: songID, cargarAudio:false });
    }

    this.src = null;
    this.disabled = true;

    this.searchSong(songID);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const incompatibilityMessage = children || (
      <p>
        Your browser does not support the <code>audio</code> element.
      </p>
    );

    if (this.disabled) {
      textoBoton =
        "El audio solicitado no esta disponible \no no hay conexion a internet";
    } else {
      textoBoton = "";
    }

    return (
      <div>
        <Tooltip title={textoBoton}>
          <span className={classes.fab}>
            <Fab
              color="primary"
              aria-label="add"
              style={style.audioPlayerWrapper(showMusic)}
              onClick={this.toggleShowBar}
              disabled={this.disabled}
            >
              <MusicNoteIcon />
            </Fab>
          </span>
        </Tooltip>

        <div style={style.audioPlayerWrapper(!showMusic)}>
          <Fab
            aria-label="add"
            size="small"
            className={classes.fab2}
            onClick={this.toggleShowBar}
          >
            X<br />
            close
          </Fab>
          <AppBar position="fixed" className={classes.appBar}>
            <Toolbar>
              <audio
                src={this.src}
                controls={false}
                loop={true}
                autoPlay={false}
                ref={ref => (this.audio = ref)}
              >
                {incompatibilityMessage}
              </audio>
              <span className={classes.timeLabel}>{getTime(currentTime)}</span>
              <Fab
                color="primary"
                aria-label="add"
                className={classes.fabButton}
                onClick={this.pressPlayButton}
              >
                {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
              </Fab>
              <Slider
                classes={{
                  root: classes.sliderRoot, // class name, e.g. `classes-nesting-root-x`
                  thumb: classes.thumb, // class /name, e.g. `classes-nesting-label-x`
                  valueLabel: classes.valueLabel,
                  track: classes.track,
                  active: classes.active,
                  rail: classes.rail
                }}
                aria-label="custom thumb label"
                valueLabelDisplay="auto"
                max={duration}
                valueLabelFormat={getTime}
                value={currentTime}
                onChange={this.sliderValueChange}
              />
              <span className={classes.timeLabel}>{getTime(duration)}</span>
              <BrowserView>
                <IconButton
                  className={classes.button}
                  aria-label="Volumen"
                  onClick={this.handleClickOpenVolume}
                >
                  {this.drawVolumeIcon()}
                </IconButton>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={this.handleCloseVolume}
                >
                  <ControlVolumen
                    volumen={currentVolume}
                    onChange={this.volumeChangeSlider}
                    volumeChangeButton={this.volumeChangeButton}
                  />
                </Popover>
              </BrowserView>
            </Toolbar>
          </AppBar>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Reproductor);
