import React from "react";

export default function TeclaF() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M 16.503065,138.27884 C 9.944543,136.21136 4.665669,131.55047 1.686506,125.19682 L 0,121.60001 v -52.16 -52.16 l 1.70266,-3.6321 C 4.086515,8.5627 8.019153,4.54585 13.028093,2.07996 L 17.253085,0 h 54.413459 54.413446 l 3.6321,1.70266 c 5.0852,2.38386 9.10205,6.3165 11.56794,11.32544 l 2.07996,4.22499 v 52.18692 52.18691 l -2.07996,4.225 c -2.46589,5.00893 -6.48274,8.94157 -11.56794,11.32543 l -3.6321,1.70266 -53.439986,0.1245 c -42.925589,0.10001 -53.970631,-0.0428 -56.136939,-0.72567 z M 45.632,88.512013 c 0.544275,-0.54428 0.768,-4.36622 0.768,-13.12 V 63.04001 h 10.194285 c 8.414201,0 10.369901,-0.17562 11.2,-1.00572 1.224531,-1.22453 1.318784,-4.33773 0.160422,-5.29908 -0.513203,-0.42593 -4.850464,-0.771 -11.04,-0.87837 L 46.72,55.68001 v -9.92 -9.92 l 11.185638,-0.32 11.185639,-0.32 0.608837,-1.73422 c 0.41297,-1.17629 0.37053,-2.25725 -0.131916,-3.36 L 68.827443,28.48001 H 54.876128 c -11.591213,0 -14.191942,0.16854 -15.373722,0.99629 L 38.08,30.47259 v 28.39137 c 0,15.615253 0.17481,28.846913 0.388474,29.403703 0.474233,1.23584 5.984089,1.42378 7.163526,0.24435 z"
        fill="#999"
      />
    </svg>
  );
}
