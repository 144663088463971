import React, { useState, useEffect } from "react";
import { AutoComplete } from "antd";
import { CancionV1, Verso, LineaVerso, CancionAutocomplete } from "../../Data/types";
import { canciones } from "../../Data/datos";
import "antd/dist/antd.css";

interface autoCompleteProps {
  selectSongID: (value: string) => void;
  cantoralRIA: boolean;
}

const renderItem = (
  item: CancionAutocomplete,
  textoBusqueda: string,
  index: number
) => {
  var textoPre = "";
  var textoFormat = "";
  var textoPost = "";
  var busquedaPorTexto = false;
  var posicion = item.Letra.indexOf(textoBusqueda);

  if (posicion > -1 && textoBusqueda !== "") {
    busquedaPorTexto = true;
    if (posicion > 10) {
      textoPre = "... ";
    }
    var longitud = textoBusqueda.length + 1;
    if (longitud > 25) longitud = 25;
    textoPre += item.Letra.toUpperCase().substring(posicion - 10, posicion - 1);
    textoFormat += item.Letra.toUpperCase().substring(
      posicion - 1,
      posicion + longitud - 1
    );
    textoPost +=
      item.Letra.toUpperCase().substring(
        posicion + longitud - 1,
        posicion + longitud + 10
      ) + " ...";
  }

  return {
    value: item.id,
    label: (
      <div key={Math.random() + index}>
        <h3>
          {item.Numero} - {item.Titulo.toUpperCase()}
        </h3>
        {busquedaPorTexto ? (
          <span>
            {textoPre}
            <span style={{ fontWeight: "bold" }}>{textoFormat}</span>
            {textoPost}
          </span>
        ) : null}
      </div>
    )
  };
};

export const AutoCompleteSong: React.FC<autoCompleteProps> = (props) => {
  const [result, setResult] = useState<CancionAutocomplete[]>([]);
  const [searchText, setSearchText] = useState<string>("");
  const [searchSongState, setSearchSongState] = useState<CancionAutocomplete[]>(
    []
  );
  const { selectSongID, cantoralRIA } = props;

  useEffect(() => {
    var emptyArray: CancionAutocomplete[] = [];
    setSearchSongState(emptyArray);
    canciones.forEach((cancion: CancionV1) => {
      var texto: string = "";
      cancion.versos.forEach((verso: Verso) => {
        var testoVerso: string = "";
        verso.lineas.forEach((linea: LineaVerso) => {
          testoVerso += " " + linea.letra;
        });
        texto += " " + testoVerso;
      });

      var numero: string = "";
      if (cancion) {
        numero = cancion.CADV2011.toString();
        if (cantoralRIA === true && cancion.Jesed2016 !== null) {
          numero = cancion.Jesed2016;
        }
      }

      var cancionTemporal: CancionAutocomplete = {
        id: cancion.id,
        NumeroList:
          cancion.CADV2011.toString() +
          " " +
          cancion.CADV2019.toString() +
          " " +
          cancion.Jesed2016,
        Titulo: cancion.nombre
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z0-9 ]/g, ""),
        Letra: texto
          .toLowerCase()
          .replace(/_/g, " ")
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s\s+/g, " "),
        LetraOriginal: texto,
        Numero: numero
      };
      if (cancion.Jesed2016 !== null)
        cancionTemporal.NumeroList +=
          " " + cancion.Jesed2016.replace(/\s+/g, "");
      setSearchSongState((oldArray) => [...oldArray, cancionTemporal]);
    });
  // eslint-disable-next-line
  }, []);

  const handleSearchSong = (value: string) => {
    var busqueda = value
      .toLowerCase()
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[^a-zA-Z0-9 ]/g, "")
      .replace(/\s\s+/g, " ");

    setSearchText(busqueda);

    var listaPorTitulo = searchSongState.filter((x: CancionAutocomplete) =>
      x.Titulo.includes(busqueda)
    );

    var listaPorLetra = searchSongState.filter((x: CancionAutocomplete) =>
      x.Letra.includes(busqueda)
    );

    var listaPorNumero = searchSongState.filter((x: CancionAutocomplete) =>
      x.NumeroList.toLowerCase().includes(busqueda)
    );

    var lista = listaPorTitulo.concat(listaPorLetra, listaPorNumero);

    let unique = lista.filter((item, i, ar) => ar.indexOf(item) === i);
    setResult(unique.sort((a, b) => a.id - b.id));
  };

  return (
    <AutoComplete
      autoFocus
      style={{ width: 300 }}
      onSearch={handleSearchSong}
      placeholder="Escribir número, titulo o texto"
      options={result.map((x, i) => {
        return renderItem(x, searchText, i)
      })}
      defaultActiveFirstOption={true}
      onSelect={selectSongID}
    ></AutoComplete>
  );
};
