import React, {useState} from "react";
import { withStyles } from '@material-ui/core/styles';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Divider from "@material-ui/core/Divider";
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import { Button, Tooltip } from "antd";

import { makeStyles } from "@material-ui/core/styles";

import { ToolOutlined } from '@ant-design/icons';
import Add from "@material-ui/icons/Add";
import KeyboardArrowUp from "@material-ui/icons/KeyboardArrowUp";

import TeclaAbajo from "../assents/TeclaAbajo";
import TeclaArriba from "../assents/TeclaArriba";
import TeclaDerecha from "../assents/TeclaDerecha";
import TeclaIzquierda from "../assents/TeclaIzquierda";
import TeclaF from "../assents/TeclaF";
import TeclaN from "../assents/TeclaN";
import TeclaP from "../assents/TeclaP";
import TeclaT from "../assents/TeclaT";
import TeclaShift from "../assents/TeclaShift";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-end"
  },
  icon: {
    margin: theme.spacing(2)
  }
}));

const StyledMenuItem = withStyles(theme => ({
  root: {
    '&:focus': {
    
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function MenuOpciones(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [openError, setOpenError] = useState(false);
  const [openTeclas, setOpenTeclas] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [cantoralRia, setCantoralRia] = useState(false);

  const handleCambioCantoral = () => {
    setCantoralRia(prev => !prev);
    props.onChangeCantoral(cantoralRia);
  };

  function handleClickOpenInfo(){
    setOpenInfo(true);
    setAnchorEl(null);
  };

  function handleCloseInfo(){
    setOpenInfo(false);
  };

  function handleClickOpenError(){
    setOpenError(true);
    setAnchorEl(null);
  };

  function handleCloseError() {
    setOpenError(false);
  };

  function handleClickOpenTeclas() {
    setOpenTeclas(true);
    setAnchorEl(null);
  }

  function handleCloseTeclas() {
    setOpenTeclas(false);
  }

  function handleClickMenu(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleCloseMenu() {
    setAnchorEl(null);
  }

  function openInNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  function handleCloseNuevaCancion() {
    openInNewTab("http://riamusica.org/cantos-nuevos/");
    setAnchorEl(null);
  }

  function handleCloseDonacion() {
    openInNewTab("http://riamusica.org/donaciones/");
    setAnchorEl(null);
  }

  //let botonInstall = mostrarBotonInstall();

  return (
    <div>
      <Dialog
        open={openTeclas}
        keepMounted
        onClose={handleCloseTeclas}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Uso de Teclas Rápidas"}
        </DialogTitle>
        <DialogContent dividers>
          <Typography variant="h6" gutterBottom>
            En toda la aplicación
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            Poner modo presentador (Shift + P) <br />
            <TeclaShift />
            <Add className={classes.icon} />
            <TeclaP />
            <br />
            Poner modo texto completo (Shift + T) <br />
            <TeclaShift />
            <Add className={classes.icon} />
            <TeclaT />
            <br />
            Poner pantalla en negro (Shift + N) <br />
            <TeclaShift />
            <Add className={classes.icon} />
            <TeclaN />
            <br />
            Poner pantalla completa (Shift + F) <br />
            <TeclaShift />
            <Add className={classes.icon} />
            <TeclaF />
            <br />
          </DialogContentText>
          <Divider />
          <Typography variant="h6" gutterBottom>
            En modo presentación
          </Typography>
          <DialogContentText id="alert-dialog-slide-description">
            Diapositiva anterior (arriba, izquierda)
            <br />
            <TeclaArriba />
            <KeyboardArrowUp className={classes.icon} />
            <TeclaIzquierda />
            <br />
            Diapositiva siguiente (abajo, derecha)
            <br />
            <TeclaAbajo />
            <KeyboardArrowUp className={classes.icon} />
            <TeclaDerecha />
            <br />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseTeclas} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openError}
        keepMounted
        onClose={handleCloseError}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Reportar un error / sugerencia"}
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText id="alert-dialog-slide-description">
            Favor enviar un correo a pablomadrigalq@gmail.com con sus sugerencias
            <br/>
            <br />
            Si tuvieron un error en el uso del app favor especificar el número y título de la canción y si estaba en modo presentación o texto. Asi como una descripción del error
          </DialogContentText>
        </DialogContent>
        <Button onClick={handleCloseError} color="primary">
          Cerrar
        </Button>
      </Dialog>
      <Dialog
        open={openInfo}
        keepMounted
        onClose={handleCloseInfo}
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle id="alert-dialog-slide-title">
          {"Información de la aplicación"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Versión 2.0 <br />
            Fecha Marzo 2020 <br />
            Aplicación creada por Pablo Madrigal <br />
            Canciones transcritas por Brechistas <br />
            Supervición por Juan Carlos Aragón <br />
            Enviar comentarios a pablomadrigalq@gmail.com
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInfo} color="primary">
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
      <Tooltip title="Menu">
        <Button
          shape="circle"
          type="text"
          size="large"
          icon={<ToolOutlined />}
          onClick={handleClickMenu}
          style={{color: '#ffffff' }} 
        />
      </Tooltip>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
      >
      <StyledMenuItem>
          <Typography component="div">
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>2009</Grid>
              <Grid item>
                <Switch
                checked={!cantoralRia}
                onChange={handleCambioCantoral}
                value="cantoralRia"
                color="primary"
                />
              </Grid>
              <Grid item>2016</Grid>
            </Grid>
          </Typography>
        </StyledMenuItem>
        <MenuItem onClick={handleClickOpenTeclas}>
          Teclas de acceso rápido
        </MenuItem>
        <MenuItem onClick={handleCloseDonacion}>Donaciones</MenuItem>
        <MenuItem onClick={handleCloseNuevaCancion}>
          Enviar canción nueva
        </MenuItem>
        <MenuItem onClick={handleClickOpenError}>Reportar un error</MenuItem>
        <MenuItem onClick={handleClickOpenInfo}>Ayuda</MenuItem>
      </Menu>
    </div>
  );
}
