import React, {useState, useEffect} from "react";
import { Typography, Paper, Grid } from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import Autores from "./Autores";
import SoloTextoCanto from "./SoloTextoCanto";
import { canciones } from "../Data/datos";

import ReproductorMusical from "./ReproductorMusical.js";

const styles = theme => ({
  root: {
    ...theme.mixins.gutters(),
    paddingTop: theme.spacing(3),
    paddingBotton: theme.spacing(3)
  },
  reproductor:{
    height: "24px",
    backgroundColor: "red"
  }
});

function TextoCompleto(props) {
  const [song, setSong] = useState(canciones[0]);
  const [songLabel, setSongLabel] = useState(1);
  const [showMusic, setShowMusic] = useState(false);

  const { classes } = props;

  useEffect(() => {
    let songsList = canciones.filter(x => x.id === props.songID);
    var number = songsList[0].Jesed2016;
    if(props.tipoCantoral === false){
      number = songsList[0].CADV2011;
    }

    if (songsList[0]) {
      setSong(songsList[0]);
      setSongLabel(number + " " + songsList[0].nombre);
    } else {
      songsList = canciones.filter(x => x.id === 1);
      setSong(songsList[0]);
      setSongLabel(number + " " + songsList[0].nombre);
    }
  }, [props.songID, props.tipoCantoral]);


  return (
    <div>
      <Grid container spacing={3} justify="center">
        <Paper className={classes.root}>
          <Typography variant="h5" color="inherit" align="center">
            <br />
            {songLabel}
            <br />
          </Typography>
          <Autores
            autorMusica={song.autorMusica}
            autorLetra={song.autorLetra}
            autorMyL={song.autorMYL}
            autorTraductor={song.autorTrad}
            tipoImpresion={1}
          />
          <br />
          <SoloTextoCanto songID={props.songID} />
        </Paper>
        {showMusic && <div className={classes.reproductor}></div>}
      </Grid>
      <ReproductorMusical songID={props.songID} showMusic={showMusic} setShowMusic={setShowMusic}/>
    </div>
  );
  
}

TextoCompleto.defaultProps = {
  tipoCantoral: true
};

export default withStyles( styles )( TextoCompleto );