import React from "react";
import { View, StyleSheet } from "react-native";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: "#00fe00",
  }
});

export default function Slide(props) {
    return (
      <View style={styles.container}>
        <div/>
      </View>
    );
  }

