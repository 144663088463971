import React from "react";
import ReactDOM from "react-dom";
import { View, StyleSheet } from "react-native";
import "mousetrap";
import Cantoral from "./Cantoral.js";
import * as serviceWorker from "./serviceWorker";

const styles = StyleSheet.create({
  container: {
    flex: 1,
    //height: Dimensions.get("screen").height
  },
  blackScreen:{
    flex: 1,
    //height: Dimensions.get("screen").height
  }
});

(function(Mousetrap) {
  var _globalCallbacks = {};
  var _originalStopCallback = Mousetrap.prototype.stopCallback;

  Mousetrap.prototype.stopCallback = function(e, element, combo, sequence) {
    var self = this;

    if (self.paused) {
      return true;
    }

    if (_globalCallbacks[combo] || _globalCallbacks[sequence]) {
      return false;
    }

    return _originalStopCallback.call(self, e, element, combo);
  };

  Mousetrap.prototype.bindGlobal = function(keys, callback, action) {
    var self = this;
    self.bind(keys, callback, action);

    if (keys instanceof Array) {
      for (var i = 0; i < keys.length; i++) {
        _globalCallbacks[keys[i]] = true;
      }
      return;
    }

    _globalCallbacks[keys] = true;
  };

  Mousetrap.init();
})(window.Mousetrap);

class App extends React.Component {

  state = {
    pantallaCompleta: false
  };

  componentDidMount() {
  }

  toogleFullScreen = () => {
    this.setState({
          pantallaCompleta: !this.state.pantallaCompleta
        });
    }

  render() {
    return (
      <View style={[this.state.pantallaCompleta ? styles.container : styles.blackScreen]}>
        <Cantoral
          cambiarPantalla = {this.toogleFullScreen}
        />
      </View>
    );
  }
}

ReactDOM.render(<App />, document.getElementById('root'));

serviceWorker.register();