import React from "react";

export default function TeclaAbajo() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M16.503 137.64c-6.563-2.072-11.838-6.73-14.816-13.083L0 120.96V17.28l1.703-3.632C4.087 8.563 8.019 4.546 13.028 2.08L17.253 0H126.08l3.632 1.703c5.085 2.384 9.102 6.316 11.568 11.325l2.08 4.225V121.6l-1.825 3.52c-2.45 4.725-6.325 8.642-10.975 11.095l-3.84 2.025-53.76.126c-43.25.102-54.287-.04-56.457-.725zM60.02 64.446c7.293-7.323 13.26-13.568 13.26-13.879 0-.868-2.686-3.526-3.563-3.526-.429 0-5.107 4.464-10.395 9.92-5.288 5.456-9.927 9.92-10.309 9.92-.486 0-.693-7.813-.693-26.194 0-22.96-.124-26.319-1.006-27.2-1.169-1.17-2.42-1.273-4.156-.343-1.193.638-1.238 1.616-1.238 27.093 0 14.537-.21 26.641-.468 26.899-.258.257-4.939-4.2-10.403-9.906-8.72-9.106-10.116-10.317-11.411-9.906-.812.257-1.8 1.179-2.196 2.048-.684 1.501-.054 2.246 12.673 14.984C40.52 74.77 43.871 77.76 45.135 77.76c1.263 0 4.575-2.962 14.885-13.315z"
        fill="#999"
      />
    </svg>
  );
}
