import React from "react";

export default function TeclaN() {
  return (
    <svg viewBox="0 0 150 150" width={40} height={40}>
      <path
        d="M 16.50306,137.63884 C 9.9445397,135.57136 4.6656697,130.91047 1.68651,124.55681 L 0,120.96001 V 69.120011 17.280001 L 1.70266,13.647908 C 4.08651,8.5627 8.0191497,4.54585 13.02809,2.079958 L 17.25308,0 H 71.66654 126.08 l 3.6321,1.702661 c 5.0852,2.383854 9.10205,6.316493 11.56794,11.325433 l 2.07996,4.224992 v 51.853465 51.853459 l -1.8073,3.91065 c -2.26157,4.89359 -6.63403,9.24536 -11.6327,11.57766 l -3.84,1.79169 -53.44,0.1245 c -42.92559,0.1 -53.97063,-0.0427 -56.13694,-0.72567 z M 45.71654,87.732361 c 0.55216,-0.6653 0.72398,-7.2012 0.64356,-24.480002 -0.0603,-12.964791 0.11152,-23.572357 0.38192,-23.572357 0.2704,0 1.40679,1.728001 2.52529,3.840001 15.58599,29.429958 22.51355,41.898468 24.10802,43.390618 1.70061,1.59149 2.31594,1.76942 5.53321,1.6 3.1234,-0.16448 3.73039,-0.41003 4.4262,-1.79061 1.04109,-2.06567 1.19257,-55.717841 0.16274,-57.642083 -0.49602,-0.926829 -1.34845,-1.237926 -3.392,-1.237926 -1.50121,0 -3.07508,0.3456 -3.49748,0.768 -0.56873,0.56873 -0.768,6.71289 -0.768,23.680001 0,12.601606 -0.22414,22.912008 -0.49809,22.912008 -0.27394,0 -2.04281,-2.952 -3.93082,-6.56 C 59.29394,45.48399 51.3443,31.098064 49.93262,29.771859 47.56473,27.547338 41.3602,27.119799 39.43173,29.048268 38.09629,30.383713 38.08,30.745772 38.08,59.093335 c 0,15.781336 0.192,28.885336 0.42667,29.120006 0.81968,0.81969 6.44168,0.44464 7.20987,-0.48098 z"
        fill="#999"
      />
    </svg>
  );
}
